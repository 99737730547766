<div class="profile-card">
  <div class="profile-image-wrapper">
    <img
      class="profile-image"
      src="{{ member.image }}"
      alt="{{ member.name }}"
    />
  </div>
  <div class="profile-content">
    <div class="flex flex-col">
      <h3 class="profile-title">{{ member.name }}</h3>
      <h4 class="profile-subtitle pb-2">{{ member.role }}</h4>
      <p class="profile-text">
        {{ member.description }}
      </p>
    </div>
    <div class="profile-action-buttons">
      <a href="mailto:{{ member.email }}" target="_blank">
        <button nzSize="large" nz-button nzDanger nzShape="circle">
          <i class="fa social-icon fa-envelope"></i>
        </button>
      </a>
      <a href="tel:{{ member.phone }}" target="_blank">
        <button nzSize="large" nz-button nzDanger nzShape="circle">
          <i class="fa social-icon fa-phone"></i>
        </button>
      </a>
      <a href="{{ member.linkedInUrl }}" target="_blank">
        <button nzSize="large" nz-button nzDanger nzShape="circle">
          <i class="fa social-icon fa-linkedin"></i>
        </button>
      </a>
    </div>
  </div>
</div>
