import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-public-layout',
  templateUrl: './public-layout.component.html',
  styleUrls: ['./public-layout.component.scss'],
})
export class PublicLayoutComponent implements OnInit {
  @ViewChild('content', { read: ElementRef, static: false })
  content!: ElementRef;
  @ViewChild('header', { read: ElementRef, static: false }) header!: ElementRef;
  constructor(public translate: TranslateService) {
    // if (localStorage.getItem('language')) {
    //   // @ts-ignore
    //   translate.setDefaultLang(localStorage.getItem('language'));
    //   // @ts-ignore
    //   translate.use(localStorage.getItem('language'));
    // } else {
    //   translate.setDefaultLang('en');
    //   translate.use('en');
    //   localStorage.setItem('language', 'en');
    // }

    //TODO temporary for english only
    localStorage.setItem('language', 'en');
  }
  ngOnInit() {
    setTimeout(() => {
      const header = document.getElementById('motus-header');
      const content = document.getElementById('motus-content');

      if (content) {
        content.style.marginTop = header?.offsetHeight + 'px';
      }
    }, 0);
  }
}
