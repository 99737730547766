import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { VacanciesService } from '../../../services/vacancies.service';
import { TranslateService } from '@ngx-translate/core';
import { FilterSearchService } from '../../../services/filter-search.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IExpertise, IRegion } from '../../../interfaces/data';
import { ActivatedRoute, Router } from '@angular/router';

@UntilDestroy()
@Component({
  selector: 'app-career-searcher',
  templateUrl: './career-searcher.component.html',
  styleUrls: ['./career-searcher.component.scss'],
})
export class CareerSearcherComponent implements OnInit {
  @Input() showViewAll = false;
  @Input() hasSecondaryColor = false;
  @Output() notifyParent: EventEmitter<any> = new EventEmitter<any>();
  @Output() emitNavigate = new EventEmitter<boolean>();

  public searchFilter = '';
  public expertiseFilter = '';
  public regionFilter = '';

  public regions: IRegion[] = [];
  public expertises: IExpertise[] = [];

  constructor(
    private vacanciesService: VacanciesService,
    public translate: TranslateService,
    public filterSearchService: FilterSearchService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.regionFilter = Array.isArray(params.regions)
        ? params.regions[0]?.toString()
        : params.regions
        ? params.regions?.toString()
        : '';

      this.expertiseFilter = Array.isArray(params.fields)
        ? params.fields[0]?.toString()
        : params.fields
        ? params.fields?.toString()
        : '';

      this.searchFilter = params.search ? params.search : '';
    });

    this.getFields();
    this.getRegions();
  }

  private getFields(): void {
    this.vacanciesService
      .getFields()
      .pipe(untilDestroyed(this))
      .subscribe((fields: IExpertise[]) => {
        this.expertises = fields;
      });
  }

  private getRegions(): void {
    this.vacanciesService
      .getRegions()
      .pipe(untilDestroyed(this))
      .subscribe((regions: IRegion[]) => {
        this.regions = regions;
      });
  }
}
