import { gql } from 'apollo-angular';

export const AllVacancies = gql`
  {
    vacancies {
      id
      slug
      createdAt
      updatedAt
      title {
        en
        nl
      }
      description {
        en
        nl
      }
      field {
        name {
          en
          nl
        }
      }
      categories {
        name {
          en
          nl
        }
      }
      region {
        name {
          en
          nl
        }
      }
      employment {
        name {
          en
          nl
        }
      }
      salaryRange {
        min
        max
      }
    }
  }
`;

export const AllVacanciesFilter = gql`
  query Vacancies(
    $regions: [String]
    $fields: [String]
    $educations: [String]
    $employments: [String]
    $search: String
  ) {
    vacancies(
      regions: $regions
      fields: $fields
      educations: $educations
      employments: $employments
      search: $search
    ) {
      id
      slug
      createdAt
      updatedAt
      title {
        en
        nl
      }
      description {
        en
        nl
      }
      field {
        name {
          en
          nl
        }
      }
      categories {
        name {
          en
          nl
        }
      }
      region {
        name {
          en
          nl
        }
      }
      employment {
        name {
          en
          nl
        }
      }
      salaryRange {
        min
        max
      }
    }
  }
`;

export const AllRegions = gql`
  {
    regions {
      name {
        en
        nl
      }
      slug
    }
  }
`;

export const AllEducations = gql`
  {
    educations {
      name {
        en
        nl
      }
      slug
    }
  }
`;

export const AllFields = gql`
  {
    fields {
      name {
        en
        nl
      }
      slug
    }
  }
`;

export const AllBlogs = gql`
  {
    blogs {
      createdAt
      updatedAt
      id
      title {
        en
        nl
      }
      description {
        en
        nl
      }
      slug
      author {
        name
        email
        phone
      }
    }
  }
`;

export const VacancyById = gql`
  query GetVacancyById($vacancyId: ID!) {
    vacancy(id: $vacancyId) {
      createdAt
      updatedAt
      id
      slug
      title {
        en
        nl
      }
      description {
        en
        nl
      }
      field {
        name {
          en
          nl
        }
      }
      categories {
        name {
          en
          nl
        }
      }
      region {
        name {
          en
          nl
        }
      }
      employment {
        name {
          en
          nl
        }
      }
      owner {
        name
        email
        phone
      }
      salaryRange {
        min
        max
      }
    }
  }
`;

export const BlogById = gql`
  query GetBlogById($blogId: ID!) {
    blog(id: $blogId) {
      createdAt
      updatedAt
      id
      slug
      title {
        en
        nl
      }
      description {
        en
        nl
      }
      author {
        name
        email
        phone
      }
    }
  }
`;
