import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Draggable from 'gsap/Draggable';

@Component({
  selector: 'app-about-us-page',
  templateUrl: './about-us-page.component.html',
  styleUrls: ['./about-us-page.component.scss']
})
export class AboutUsPageComponent implements OnInit, OnDestroy {

  public timeline!: gsap.core.Timeline;

  constructor(private translate: TranslateService, private titleService: Title) {
  }

  ngOnInit(): void {
    this.setTitle();
    this.setAnimations();
  }

  ngOnDestroy(): void {
    this.timeline.clear();
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Over ons';
    } else {
      title = 'About us';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

  private setAnimations(): void {
    gsap.registerPlugin(ScrollTrigger, Draggable);

    gsap.from('.large-text-block', {
      opacity: 0,
      y: 50,
      duration: 0.8
    });

    const card = document.getElementById('card-section');

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: card,
        start: 'bottom bottom',
        end: 'bottom bottom',
        toggleActions: 'play none none none'
      }
    });

    this.timeline.from('.about-info-card-1', {
      opacity: 0,
      y: 10,
      duration: 0.3
    }).from('.about-info-card-2', {
      opacity: 0,
      y: 10,
      duration: 0.3
    }).from('.about-info-card-3', {
      opacity: 0,
      y: 10,
      duration: 0.3
    }).from('.about-info-card-4', {
      opacity: 0,
      y: 10,
      duration: 0.3
    });

    this.timeline.play();
  }

}
