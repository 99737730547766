import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-employer-page',
  templateUrl: './employer-page.component.html',
  styleUrls: ['./employer-page.component.scss'],
})
export class EmployerPageComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private titleService: Title
  ) {}
  ngOnInit() {
    this.setTitle();
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Voor de werkgevers';
    } else {
      title = 'Work with Motus';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }
}
