import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

interface IParams {
  educations?: Array<string>;
  regions?: Array<string>;
  fields?: Array<string>;
  search?: string;
}

@Injectable({
  providedIn: 'root',
})
export class FilterSearchService {
  constructor(private router: Router) {}

  public applyFilters(params: IParams, searchFilter: string): void {
    this.router.navigate(['/jobs'], {
      queryParams: {
        regions: params.regions,
        educations: params.educations,
        fields: params.fields,
        search: searchFilter,
      },
    });
  }

  public removeFilters(): void {
    this.router.navigate(['/jobs']);
    // Optionally, you may add further logic here if needed
  }
}
