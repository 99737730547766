<div
  class="job-card relative z-10 rounded-2xl bg-white p-6 xl:p-8 shadow-lg cursor-pointer h-full whitespace-pre-line"
  (click)="onClick()"
>
  <div class="flex flex-col justify-between h-full">
    <div>
      <h3 class="text-base md:text-lg text-secondary-500">
        {{ job?.field?.name?.en }}
      </h3>
      <h2 class="text-xl md:text-2xl text-gray-800 font-medium">
        {{ job?.title?.en }}
      </h2>
    </div>
    <div
      [ngClass]="gridMode ? 'flex-col gap-2' : 'flex-col md:flex-row'"
      class="flex flex-wrap w-full text-lg font-black pt-2"
    >
      <div class="pr-6" *ngIf="job?.region">
        <i
          class="fa fa-map-marker job-icon pr-2 text-secondary-500"
          aria-hidden="true"
        ></i>
        <span>{{ job?.region?.name?.en }}</span>
      </div>
      <div class="pr-6" *ngIf="job?.salaryRange?.min">
        <i
          class="fa fa-euro job-icon pr-2 text-secondary-500"
          aria-hidden="true"
        ></i>
        <span>{{ job?.salaryRange?.min }} - {{ job?.salaryRange?.max }}</span>
      </div>
      <div class="pr-6" *ngIf="job?.employment">
        <i
          class="fa fa-clock-o job-icon pr-2 text-secondary-500"
          aria-hidden="true"
        ></i>
        <span>{{ job?.employment?.name?.en }}</span>
      </div>
      <!--      <div class="pr-6">-->
      <!--        <i-->
      <!--          class="fa fa-calendar job-icon pr-2 text-secondary-500"-->
      <!--          aria-hidden="true"-->
      <!--        ></i>-->
      <!--        <span>{{ job?.updatedAt | date : "dd/MM/yyyy" }}</span>-->
      <!--      </div>-->
    </div>
    <div [ngClass]="gridMode ? 'hidden' : 'block'" class="pt-4">
      <p
        class="job-description overflow-hidden"
        [innerHTML]="job?.description?.en | sanitizeHtml"
      ></p>
    </div>
    <a class="text-primary-300 hover:text-primary-100 text-lg pt-8"
      >View vacancy</a
    >
  </div>
</div>
