import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ITeamMember } from '../../../../interfaces/team-member';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MemberCardComponent } from 'src/app/core/components/member-card/member-card.component';
import { ContactFormComponent } from '../../../components/contact-form/contact-form.component';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Draggable from 'gsap/Draggable';
import { allTeamMembers } from '../../../../shared/variables';
import { DragScrollComponent } from 'ngx-drag-scroll';

@Component({
  selector: 'app-team-page',
  templateUrl: './family-page.component.html',
  styleUrls: [
    './family-page.component.scss',
    '../../public-layout.component.scss',
  ],
})
export class FamilyPageComponent implements OnInit, OnDestroy {
  public timeline!: gsap.core.Timeline;
  @ViewChild('introText') introText!: ElementRef;
  protected readonly allTeamMembers = allTeamMembers;

  @ViewChild('nav', { read: DragScrollComponent }) ds!: DragScrollComponent;
  @ViewChild('globeSection', { read: ViewContainerRef })
  globeSection!: ViewContainerRef;
  globeSectionRendered = false;

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (!this.globeSectionRendered && window.scrollY > 1000) {
      this.globeSectionRendered = true;
      import('../../../components/globe/globe.component').then(
        ({ GlobeComponent }) => {
          const { instance } =
            this.globeSection.createComponent(GlobeComponent);
        }
      );
    }
  }

  constructor(private titleService: Title) {}

  ngOnInit(): void {
    this.titleService.setTitle('The Motus Family - Motus People');
    this.setAnimations();
  }

  ngOnDestroy(): void {
    this.timeline.clear();
  }

  private setAnimations(): void {
    gsap.registerPlugin(ScrollTrigger, Draggable);

    gsap.from('.large-text-block', {
      opacity: 0,
      y: 50,
      duration: 0.8,
    });

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.introText?.nativeElement,
        start: 'bottom bottom',
        end: 'bottom bottom',
        toggleActions: 'play none none none',
      },
    });

    this.timeline.play();
  }
}
