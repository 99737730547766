<section class="relative flex items-center overflow-hidden py-48 bg-gray-100">
  <img
    ngSrc="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
    alt="background-logo"
    class="w-full h-full top-0 left-0 object-cover opacity-60 fixed"
    height="16"
    width="16"
  />

  <div class="container z-10">
    <div #introText>
      <h1
        class="text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold"
      >
        {{ "FAMILY_PAGE.SECTION_HOME.TITLE" | translate }}
      </h1>
      <p
        class="max-w-3xl text-lg md:text-2xl font-bold leading-snug mt-4 pb-12"
      >
        Motus People drives your tech career forward. We link talent with
        industry leaders, delving beyond resumes to grasp your ambitions and
        dreams. With personalized service, we shape tech careers profoundly.
      </p>
      <a [routerLink]="['/job/motus-apply']">
        <button nzType="primary" nzShape="round" nz-button nzSize="large">
          Join our family now
        </button></a
      >
    </div>
  </div>
</section>

<section
  class="team-section bg-fixed bg-gradient-to-b from-primary-400 to-primary-800"
>
  <div
    class="relative container my-12 md:my-24 border-l-4 border-dashed border-secondary-500"
  >
    <h2 class="team-title">
      The M<span
        ><img
          src="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Oranje.svg"
          alt="motus-logo"
          class="-ml-1 mb-2 logo-o" /></span
      >tus People
    </h2>
    <h3 class="team-subtitle">
      Meet Our Expert Team, Uniting Talent with Opportunity
    </h3>
    <div class="pt-12 lg:pt-24 flex flex-col gap-16 lg:gap-32">
      <ng-container *ngFor="let member of allTeamMembers; let odd = odd">
        <div
          class="group flex flex-col justify-between gap-8 lg:gap-16"
          [ngClass]="odd ? 'lg:flex-row-reverse' : 'lg:flex-row'"
        >
          <div
            class="flex justify-center h-full p-12 pb-0 w-full lg:w-2/5 rounded-2xl shadow-xl pattern-zigzag-3d pattern-secondary-500 pattern-bg-transparent pattern-opacity-100 pattern-size-24"
          >
            <img
              class="max-h-[28rem] transition-all duration-300"
              [ngClass]="[
                odd
                  ? 'scale-x-[-1] md:group-hover:scale-x-[1]'
                  : 'md:group-hover:scale-x-[-1]',
                member.image
                  ? 'grayscale-0 md:grayscale group-hover:grayscale-0'
                  : 'grayscale-0'
              ]"
              src="{{
                member.image
                  ? member.image
                  : '/assets/images/silhouette-portrait-primary.png'
              }}"
              alt="{{ member.name }}"
            />
          </div>
          <div class="w-full lg:w-3/5 flex flex-col gap-4">
            <div class="flex flex-col">
              <div
                class="text-white font-['Roboto'] text-3xl lg:text-4xl xl:text-5xl font-extrabold"
              >
                {{ member.name }}
              </div>
              <div class="text-gray-300 text-xl lg:text-2xl">
                {{ member.role }}
              </div>
            </div>
            <p class="text-gray-200">{{ member.description }}</p>
            <div class="flex pt-2 gap-4 text-secondary-500;">
              <a href="mailto:{{ member.email }}" target="_blank">
                <button
                  nzSize="large"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                >
                  <i class="fa social-icon fa-envelope"></i>
                </button>
              </a>
              <a
                *ngIf="member.phone"
                href="tel:{{ member.phone }}"
                target="_blank"
              >
                <button
                  nzSize="large"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                >
                  <i class="fa social-icon fa-phone"></i>
                </button>
              </a>
              <a href="{{ member.linkedInUrl }}" target="_blank">
                <button
                  nzSize="large"
                  nz-button
                  nzType="default"
                  nzShape="circle"
                >
                  <i class="fa social-icon fa-linkedin"></i>
                </button>
              </a>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="flex flex-col lg:flex-row justify-between gap-8 lg:gap-16">
        <div
          class="flex justify-center h-full p-12 pb-0 w-full lg:w-2/5 rounded-2xl shadow-xl pattern-zigzag-3d pattern-secondary-500 pattern-bg-transparent pattern-opacity-100 pattern-size-24"
        >
          <img
            class="max-h-[28rem] grayscale"
            src="/assets/images/silhouette-portrait.png"
            alt="Silhouette of a portrait"
          />
        </div>
        <div class="w-full lg:w-3/5 flex flex-col gap-4">
          <div
            class="text-white font-['Roboto'] text-3xl lg:text-4xl xl:text-5xl font-extrabold"
          >
            You?
          </div>
          <p class="text-gray-200 pb-2">
            Ready to join forces with our dynamic team? Your next career move
            starts here. Explore our current openings and apply now to become a
            valued member of our innovative team. We're looking forward to
            welcoming you aboard!
          </p>
          <a [routerLink]="['/job/motus-apply']">
            <button class="w-fit" nz-button nzShape="round" nzSize="large">
              Become part of our team
            </button>
          </a>
        </div>
      </div>
    </div>
    <div
      class="absolute left-0 bottom-0 w-4 h-4 bg-secondary-500 rounded-full -ml-2.5 -mb-5"
    ></div>
  </div>
</section>

<section class="relative bg-white container py-16 md:py-32">
  <h2 class="text-2xl md:text-4xl text-primary-500 font-black">
    {{ "FAMILY_PAGE.SECTION_CLOSING.TITLE" | translate }}
  </h2>
  <p class="max-w-3xl py-4 pb-8">
    {{ "FAMILY_PAGE.SECTION_CLOSING.PARAGRAPH" | translate }}
  </p>
  <a [routerLink]="['/job/motus-apply']">
    <button nzType="primary" nzShape="round" nz-button nzSize="large">
      Join our family now
    </button>
  </a>
</section>

<section class="relative z-10 -mb-64 md:-mb-48">
  <div class="relative">
    <h2
      class="mix-blend-difference globe-title absolute z-50 top-0 left-0 right-0 text-gray-100 font-black pt-16 text-4xl md:text-5xl xl:text-6xl text-center"
    >
      Discover the Origins of Our Family
    </h2>
    <div class="h-[1000px]">
      <div #globeSection></div>
    </div>
  </div>
</section>

<section
  class="relative bg-gradient-to-b from-black via-black via-35% to-primary-500"
>
  <app-cta-section></app-cta-section>
</section>
