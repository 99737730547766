<section class="relative h-screen md:h-[85svh] w-full">
  <img
    class="h-full w-full object-cover brightness-50"
    src="assets/eindhoven-min.jpg"
    alt="Picture of Aveiro, Portugal"
  />
  <div
    class="h-full container flex flex-col justify-center gap-8 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
  >
    <h1
      class="text-white text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold"
    >
      Motus People Expanding Globally
    </h1>
    <p
      class="max-w-2xl text-lg md:text-2xl font-bold leading-snug text-gray-100"
    >
      Motus People is expanding globally, aiming to connect skilled
      professionals with innovative tech companies worldwide. Our mission is to
      match exceptional tech talent with leading opportunities in new
      international markets.
    </p>
    <button
      class="w-fit"
      nz-button
      [nzSize]="'large'"
      nzShape="round"
      [routerLink]="['../contact']"
    >
      Get in touch with Motus
    </button>
  </div>
</section>

<section class="relative py-12 md:py-32">
  <div class="container flex flex-col">
    <h3 class="text-3xl md:text-4xl font-semibold">Our Global Vision</h3>
    <p class="max-w-3xl mb-8 mt-4">
      At Motus People, we believe in the power of a diverse and interconnected
      world. Our expansion into new countries is driven by a commitment to:
    </p>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 text-lg mt-4">
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">
          Connect Global Talent with Opportunities
        </div>
        <p>
          Leveraging our extensive network, we aim to match talented
          professionals from various regions with top tech companies worldwide.
        </p>
      </div>
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">
          Enhance Recruitment Capabilities
        </div>
        <p>
          Our international presence ensures that we continue to deliver
          top-tier talent solutions, tailored to meet the unique needs of
          different markets.
        </p>
      </div>
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">
          Support International Tech Communities
        </div>
        <p>
          By establishing local offices, we contribute to the growth of tech
          ecosystems, partner with educational institutions, and provide
          resources for tech professionals globally.
        </p>
      </div>
    </div>
  </div>
</section>

<section>
  <img
    class="h-screen md:h-[80svh] max-h-[800px] object-bottom w-full object-cover brightness-50 md:brightness-75"
    src="assets/aveiro-min.jpeg"
    alt="Picture of Aveiro, Portugal"
  />
  <div class="container relative">
    <div
      class="container max-w-2xl flex flex-col gap-8 absolute bottom-20 left-0"
    >
      <div class="flex flex-col gap-2">
        <div class="text-base md:text-lg text-gray-300">Aveiro Office</div>
        <h2 class="text-3xl lg:text-4xl xl:text-5xl font-extrabold text-white">
          First Stop: Aveiro, Portugal
        </h2>
      </div>
      <p class="text-gray-100">
        Our journey begins in Aveiro, Portugal, known for its thriving tech
        ecosystem and skilled workforce. This strategic location enables us to
        tap into local talent and provide unparalleled recruitment services to
        our global clients. By establishing a presence in Aveiro, we aim to
        connect local tech talent with exciting opportunities around the world.
      </p>
      <button
        [routerLink]="['/jobs']"
        [queryParams]="{ regions: 'aveiro-portugal' }"
        nzShape="round"
        nzSize="large"
        class="w-fit"
        nz-button
      >
        Search for jobs in Aveiro
      </button>
    </div>
  </div>
</section>

<section class="py-12 md:py-24">
  <div class="container flex flex-col lg:flex-row-reverse items-center">
    <div class="w-full">
      <h3 class="text-3xl md:text-4xl font-semibold">Future Expansions</h3>

      <p class="pt-2">
        Our vision extends beyond Portugal. Motus People is planning to open
        more offices in various countries, each strategically chosen to enhance
        our global reach and impact. These expansions will allow us to further
        our mission of fostering innovation and growth within the tech
        recruitment industry. Stay tuned for announcements about new locations
        and how we plan to engage with tech communities worldwide.
      </p>
    </div>
    <div class="hidden lg:block w-[38rem] h-[38rem]">
      <app-globe [hasTransparentBackground]="true"></app-globe>
    </div>
  </div>
</section>

<section
  class="bg-gradient-to-b from-primary-600 to-primary-400 py-12 md:py-32"
>
  <div class="container flex flex-col">
    <h3 class="text-3xl md:text-4xl font-semibold text-white">
      Join Us on Our Journey
    </h3>

    <p class="text-gray-100 max-w-3xl pt-2">
      We invite you to join us on this exciting journey as we expand our
      horizons and empower tech talent across the globe. Whether you are a
      skilled professional seeking new opportunities or a company looking for
      top-notch talent, Motus People is here to help you achieve your goals.
      Stay tuned for updates on our initiatives, events, and opportunities as we
      grow our international footprint. Together, we can shape the future of
      tech recruitment and drive innovation forward.
    </p>
  </div>
</section>

<section class="relative bg-gradient-to-b from-primary-400 to-primary-500">
  <app-cta-section></app-cta-section>
</section>
