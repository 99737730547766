<section
  class="news-intro-section bg-gradient-to-tr from-primary-400 to-primary-700"
>
  <div class="news-intro-section-inner">
    <div class="background-motus-logo">
      <img
        src="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
        alt="background-logo"
        class="w-full h-full object-cover opacity-5"
      />
    </div>

    <div class="large-text-block px-4 md:px-0 relative z-10 h-100 lg:my-8">
      <div #introText>
        <h1 class="intro-title">Motus People News</h1>
        <h2 class="second-title">
          Uniting Our Family, Celebrating Success, and Shaping the Future
        </h2>
      </div>
    </div>
  </div>
</section>

<section class="news-section">
  <div class="container">
    <div class="news-grid">
      <ng-container *ngFor="let newsItem of newsItems">
        <app-news-card [newsItem]="newsItem"></app-news-card>
      </ng-container>
      <ng-container *ngIf="newsItems.length < 1">
        <h3 class="text-3xl text-primary-500">No articles yet, stay tuned</h3>
      </ng-container>
    </div>
  </div>
</section>

<section class="bg-gray-100">
  <app-cta-section></app-cta-section>
</section>
