<div
  class="news-card h-full flex flex-col justify-between gap-2 bg-white rounded-2xl shadow-lg cursor-pointer p-6 xl:p-8 whitespace-pre-line"
  [routerLink]="'/news/' + newsItem.id"
>
  <div class="flex flex-col gap-2">
    <div class="flex justify-between">
      <div>
        <i
          class="fa fa-user job-icon pr-2 text-secondary-500"
          aria-hidden="true"
        ></i
        >{{ newsItem?.author?.name }}
      </div>
      <div>
        <i
          class="fa fa-calendar job-icon pr-2 text-secondary-500"
          aria-hidden="true"
        ></i
        >{{ newsItem?.updatedAt | date : "dd/MM/yyyy" }}
      </div>
    </div>
    <h3 class="text-lg md:text-xl font-bold">
      {{ newsItem?.title?.en }}
    </h3>
    <p
      class="news-paragraph line-clamp-3"
      [innerHTML]="newsItem?.description?.en | sanitizeHtml"
    ></p>
  </div>

  <a class="text-primary-300 hover:text-primary-100 text-lg pt-4"
    >View article</a
  >
</div>
