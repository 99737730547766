<div
  id="motus-header"
  class="fixed header-fixed w-full"
  [@toggleHeader]="headerState"
>
  <div
    class="flex items-center justify-between text-white bg-gradient-to-r from-secondary-500 to-red-700 px-4 md:px-8 py-1"
  >
    <div class="font-['Roboto'] font-bold">Motus People Group</div>
    <div class="flex gap-4">
      <a href="https://www.instagram.com/motus_people/" target="_blank">
        <i class="text-xl text-white fa fa-instagram"></i>
      </a>
      <a href="https://www.linkedin.com/company/motus-people/" target="_blank">
        <i class="text-xl text-white fa fa-linkedin"></i>
      </a>
    </div>
  </div>
  <nav class="bg-white px-4 md:px-8 py-2 shadow-xl">
    <div class="mx-auto w-full gap-4 flex items-center justify-between">
      <div
        class="flex justify-start w-full max-w-fit text-sm font-bold leading-relaxed text-primary-500"
        (click)="showMenu = false"
      >
        <a class="" routerLink="/">
          <img
            class="w-36 md:w-40 h-14 lg:h-16"
            src="./assets/SVG/motus_color.svg"
            alt="Motus Logo"
          />
        </a>
      </div>
      <div class="flex items-center justify-center lg:hidden">
        <!--        <a-->
        <!--          (click)="showLanguageModal()"-->
        <!--          class="flex items-center justify-center text-primary-500 font-bold py-0"-->
        <!--          nz-button-->
        <!--          nzType="link"-->
        <!--        >-->
        <!--          <i nz-icon nzType="global" nzTheme="outline"></i>-->
        <!--        </a>-->
        <button
          class="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent outline-none focus:outline-none"
          type="button"
          (click)="toggleNavbar()"
        >
          <i class="text-primary-500 fa fa-bars"></i>
        </button>
      </div>

      <!--    DESKTOP-->
      <div class="hidden lg:flex flex-grow items-center w-full">
        <ng-container *ngTemplateOutlet="menuItems"></ng-container>
      </div>

      <div class="hidden lg:flex items-center">
        <ng-container *ngTemplateOutlet="actionButtons"></ng-container>
      </div>
    </div>
    <div class="flex lg:hidden items-center w-full" [@displayState]="showMenu">
      <ng-container *ngTemplateOutlet="menuItems"></ng-container>
    </div>
    <!--    MOBILE-->
  </nav>

  <ng-template #menuItems>
    <ul nz-menu [nzMode]="deviceService.isMobile() ? 'vertical' : 'horizontal'">
      <li nz-submenu nzTitle="Career Opportunities" nzIcon="user">
        <ul>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['jobs']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Discover careers
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            [routerLink]="['contact']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Contact us
            </a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzTitle="For Employers" nzIcon="audit">
        <ul>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['employer']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Work with us
            </a>
          </li>
          <li (click)="showMenu = false" nz-menu-item [routerLink]="['family']">
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Our family
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            [routerLink]="['contact']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Contact us
            </a>
          </li>
        </ul>
      </li>
      <li nz-submenu nzTitle="About Motus" nzIcon="team">
        <ul>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['about']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              About Motus
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['family']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Our family
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['abroad']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Motus Abroad
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            nzMatchRouter
            [routerLink]="['news']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Motus news
            </a>
          </li>
          <li
            (click)="showMenu = false"
            nz-menu-item
            [routerLink]="['contact']"
          >
            <a [routerLinkActive]="['link-active']" nz-button nzType="link">
              Contact us
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </ng-template>

  <ng-template #actionButtons>
    <!--    <div>-->
    <!--      <div-->
    <!--        class="px-3 py-2 flex items-center text-xs uppercase font-black leading-snug hover:opacity-75 w-full lg:w-auto"-->
    <!--      >-->
    <!--        <a-->
    <!--          (click)="showLanguageModal()"-->
    <!--          nz-button-->
    <!--          nzType="link"-->
    <!--          nzShape="circle"-->
    <!--        >-->
    <!--          <span nz-icon nzType="global" nzTheme="outline"></span>-->
    <!--        </a>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="nav-item">
      <div
        class="py-2 flex gap-3 items-center text-xs uppercase font-black leading-snug w-full lg:w-auto"
      >
        <button
          nz-button
          nzType="default"
          [nzSize]="'large'"
          nzShape="round"
          [routerLink]="['jobs']"
        >
          <span nz-icon nzType="search"></span> Search careers
        </button>
        <button
          nz-button
          nzType="primary"
          [nzSize]="'large'"
          nzShape="round"
          [routerLink]="['contact']"
        >
          Get in touch
        </button>
      </div>
    </div>
  </ng-template>
</div>
