import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private translate: TranslateService, private titleService: Title) { }

  ngOnInit(): void {
    this.setTitle();
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Privacybeleid';
    } else {
      title = 'Privacy policy';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

}
