import {
  Component,
  ComponentFactoryResolver,
  HostListener,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  IBlog,
  IEducation,
  IExpertise,
  IRegion,
  IVacancy,
} from '../../../../interfaces/data';
import { VacanciesService } from '../../../../services/vacancies.service';
import { NguCarousel, NguCarouselConfig } from '@ngu/carousel';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Apollo } from 'apollo-angular';
import { AllBlogs, AllVacanciesFilter } from '../../../../graphql/queries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { FilterSearchService } from '../../../../services/filter-search.service';

@UntilDestroy()
@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit {
  public latestJobs: IVacancy[] = [];
  public latestNewsItems: IBlog[] = [];
  public loading = false;

  @ViewChild('globeSection', { read: ViewContainerRef })
  globeSection!: ViewContainerRef;
  globeSectionRendered = false;

  // @HostListener('window:scroll', ['$event'])
  // onScroll() {
  //   if (!this.globeSectionRendered && window.scrollY > 1000) {
  //     this.globeSectionRendered = true;
  //     import('../../../components/globe/globe.component').then(
  //       ({ GlobeComponent }) => {
  //         const { instance } =
  //           this.globeSection.createComponent(GlobeComponent);
  //       }
  //     );
  //   }
  // }

  constructor(
    private apollo: Apollo,
    public translate: TranslateService,
    private titleService: Title
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.setTitle();
    // this.setAnimations();

    this.apollo
      .watchQuery({
        query: AllVacanciesFilter,
        variables: {
          regions: [],
          fields: [],
          educations: [],
          employments: [],
        },
      })
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((result: any) => {
        const sortArray = [...result?.data?.vacancies];
        sortArray.sort((a: IVacancy, b: IVacancy) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
        const slicedArray = sortArray.slice(0, 6);

        this.loading = result?.loading;
        this.latestJobs = slicedArray;
      });

    this.apollo
      .watchQuery<any>({
        query: AllBlogs,
      })
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((result) => {
        const sortArray = [...result?.data?.blogs];
        sortArray.sort((a: IBlog, b: IBlog) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
        const slicedArray = sortArray.slice(0, 3);

        this.latestNewsItems = slicedArray;
      });
  }

  public setTitle(): void {
    this.titleService.setTitle(
      'Motus People - Moving Careers, Growing Technology'
    );
  }

  private setAnimations(): void {
    gsap.registerPlugin(ScrollTrigger, Draggable);
    const timeline = gsap.timeline();

    timeline
      .from('.bg-landing-view', {
        opacity: 0,
        yPercent: 100,
        xPercent: -20,
        duration: 1,
      })
      // .to('.main-title, .secondary-title, .action-buttons-hero', {
      //   opacity: 1,
      //   y: -10,
      //   duration: 0.5,
      // })
      .fromTo(
        '.bg-landing-image',
        { autoAlpha: 0 },
        { autoAlpha: 1, duration: 1 }
      )
      .fromTo(
        '.bg-logo-overlay',
        { autoAlpha: 0 },
        { autoAlpha: 0.15, duration: 1 }
      );

    timeline.play();

    document.querySelectorAll('.dashed-line-secondary').forEach((line) => {
      const scrollBox = gsap.timeline({
        scrollTrigger: {
          trigger: line,
          pin: true,
          start: 'bottom bottom',
          end: 'bottom bottom',
          toggleActions: 'play none none reverse',
        },
      });
      scrollBox.from(line, { y: -80, duration: 2.5 });
    });
  }
}
