import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-apply-error',
  templateUrl: './apply-error.component.html',
  styleUrls: ['./apply-error.component.scss']
})
export class ApplyErrorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
