<section class="relative z-20 container py-16 md:py-32">
  <div
    class="rounded-lg shadow-2xl bg-gradient-to-tr from-secondary-500 to-red-700"
  >
    <div class="flex flex-col h-full md:flex-row">
      <div class="w-full lg:w-3/4 flex flex-col gap-6 p-6 lg:p-16">
        <div
          class="text-gray-50 text-3xl lg:text-4xl xl:text-5xl font-bold pr-3 pb-2 md:pb-0 font-['Roboto']"
        >
          Get in touch with us today
        </div>
        <p class="max-w-2xl text-gray-100">
          Ready to tackle a new challenge or find the perfect match for your
          project? Let's make it happen together.
        </p>
        <div class="pb-4">
          <button
            nz-button
            [routerLink]="['/contact']"
            nzType="default"
            [nzSize]="'large'"
            nzShape="round"
          >
            Get in touch with us
          </button>
        </div>
        <hr class="border-gray-50/40" />
        <div
          class="flex flex-col gap-4 lg:gap-0 lg:flex-row justify-between items-start lg:items-center"
        >
          <div class="flex items-center gap-4">
            <img
              class="bg-white rounded-full h-20 w-20 object-cover object-top"
              src="{{ allTeamMembers[generatedNumber].image }}"
              alt="{{ allTeamMembers[generatedNumber].name }}"
            />
            <div class="flex flex-col">
              <div class="text-2xl font-['Roboto'] font-semibold text-gray-50">
                {{ allTeamMembers[generatedNumber].name }}
              </div>
              <div class="text-gray-200 text-lg">
                {{ allTeamMembers[generatedNumber].role }}
              </div>
            </div>
          </div>
          <div class="flex h-full items-center text-gray-50 gap-8">
            <div class="flex flex-col gap-1">
              <a
                href="mailto:{{ allTeamMembers[generatedNumber].email }}"
                target="_blank"
                >{{ allTeamMembers[generatedNumber].email }}</a
              >
              <a
                href="tel:{{ allTeamMembers[generatedNumber].phone }}"
                target="_blank"
                >{{ allTeamMembers[generatedNumber].phone }}</a
              >
            </div>
            <div
              class="flex w-full"
              *ngIf="allTeamMembers[generatedNumber].linkedInUrl"
            >
              <a
                href="{{ allTeamMembers[generatedNumber].linkedInUrl }}"
                target="_blank"
              >
                <i class="text-4xl fa fa-linkedin"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="hidden lg:flex md:w-1/4">
        <img
          class="bg-black object-cover w-full h-full rounded-r-lg"
          src="{{ allTeamMembers[generatedNumber].image }}"
          alt="{{ allTeamMembers[generatedNumber].name }}"
        />
      </div>
    </div>
  </div>
</section>
