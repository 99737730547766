<div class="jobs-detail relative">
  <section class="jobs-view pt-20 xl:pt-28 px-3 md:px-8 xl:px-0">
    <div
      class="flex flex-wrap mx-auto max-w-screen-lg pb-4 md:pb-10 px-2 md:px-0"
    >
      <div class="w-full">
        <h2 class="text-lg md:text-xl text-primary-500 font-semibold">
          Policies
        </h2>
        <h1 class="pt-0 md:pt-3 text-2xl md:text-4xl font-bold">
          Privacy Policy
        </h1>
      </div>
    </div>

    <div class="xl:flex w-full mx-auto max-w-screen-lg">
      <div class="w-full pb-8">
        <nz-card
          class="rounded-2xl px-0 md:px-3 shadow-lg no-styling overflow-hidden"
        >
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 1 - Begrippen
          </h1>
          <ol id="l1">
            <li data-list-text="1.">
              <p style="text-align: justify">
                In deze algemene voorwaarden wordt verstaan onder:
              </p>
              <ul id="l2">
                <li data-list-text="">
                  <p style="text-align: justify">
                    Motus People: de onderneming Motus People B.V. die zich
                    richt op arbeidsbemiddeling c.q. detachering van technisch
                    personeel en die is gevestigd te Waalre en ingeschreven in
                    het register van de KvK onder nummer 85778656;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Inlener: Iedere natuurlijke of rechtspersoon die zich door
                    tussenkomst van een uitzendonderneming voorziet van
                    uitzendkrachten;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Opdrachtgever: iedere natuurlijke of rechtspersoon die
                    gebruik maakt van de diensten van Motus People;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Inleenovereenkomst: de overeenkomst tussen een
                    uitzendonderneming en een inlener op basis waarvan een
                    uitzendkracht ten behoeve van die inlener door tussenkomst
                    van die uitzendonderneming werkzaamheden zal verrichten;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Inlenerstarief: het bedrag per uur dat de inlener aan Motus
                    People verschuldigd is voor de terbeschikkingstelling van de
                    uitzendkracht;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Uitzendkracht: iedere natuurlijke persoon die door
                    tussenkomst van een uitzendonderneming werkzaamheden
                    verricht of gaat verrichten ten behoeve van een inlener;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: left">
                    Uitzendovereenkomst: de arbeidsovereenkomst waarbij de
                    uitzendkracht door Motus People ter beschikking wordt
                    gesteld van een Inlener om krachtens een door deze met Motus
                    People gesloten inleenovereenkomst arbeid te verrichten
                    onder toezicht en leiding van die inlener;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Terbeschikkingstelling: de tewerkstelling van een
                    Uitzendkracht bij Inlener in het kader van een opdracht;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Cao: de van toepassing zijnde, steeds vigerende, cao.ABU;
                  </p>
                </li>
                <li data-list-text="">
                  <p style="text-align: justify">
                    Algemene voorwaarden: de onderhavige algemene voorwaarden.
                  </p>
                </li>
              </ul>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 2 - Toepasselijkheid en wijziging
          </h1>
          <ol id="l3">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Deze algemene voorwaarden beschrijven de voorwaarden waaronder
                Motus People haar werkzaamheden als werving- en uitzendbureau
                uitvoert en zijn van toepassing op iedere overeenkomst,
                aanbieding, offerte, opdracht, vervolgopdracht, gewijzigde
                opdracht en/of aanvullende opdracht aan Motus People en/of aan
                personen die ten behoeve van Motus People werkzaam zijn of
                waren, tenzij voorafgaand aan de totstandkoming van
                desbetreffende overeenkomst of rechtsbetrekking schriftelijk
                anders is overeengekomen.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Ingeval van strijdigheid tussen de bepalingen van de
                Inleenovereenkomst en deze Algemene Voorwaarden, zal de
                Inleenovereenkomst prevaleren.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Indien deze Algemene Voorwaarden eenmaal van toepassing zijn
                geweest op een rechtsverhouding tussen Motus People en Inlener,
                wordt Inlener geacht bij voorbaat te hebben ingestemd met de
                toepasselijkheid van de Algemene Voorwaarden op nadien gesloten
                en te sluiten overeenkomsten, inclusief wijzigingen en
                aanvullingen van de Algemene Voorwaarden.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="padding-top: 4pt; text-align: justify">
                Motus People is bevoegd deze algemene voorwaarden te wijzigen.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                De toepasselijkheid van algemene voorwaarden waarnaar de Inlener
                verwijst, wordt uitdrukkelijk uitgesloten.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 3 – Offertes en aanbiedingen
          </h1>
          <li data-list-text="1.">
            <p style="text-align: justify">
              Motus People kan gebruik maken van offertes en aanbiedingen,
              waarbij de geldigheidsduur steeds zal worden vermeld.
            </p>
          </li>
          <li data-list-text="2.">
            <p style="line-height: 10pt; text-align: justify">
              Alle offertes van Motus People zijn geheel vrijblijvend.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <h1 style="text-indent: 0pt; text-align: left">
              Artikel 4 - De Inleenovereenkomst
            </h1>
            <ol id="l5">
              <li data-list-text="1.">
                <p style="text-align: justify">
                  Er komt een Inleenovereenkomst tot stand tussen Motus People
                  en de Inlener, nadat deze overeenkomst door beide partijen is.
                  De Inleenovereenkomst kan ook tot stand komen nadat Inlener
                  schriftelijk akkoord heeft gegeven op de door Motus People
                  uitgebrachte offerte of nadat de Uitzendkracht met zijn
                  werkzaamheden is gestart.
                </p>
              </li>
              <li data-list-text="2.">
                <p style="text-align: justify">
                  Indien in de acceptatie voorbehouden of wijzigingen ten
                  opzichte van het aanbod/de offerte worden aangebracht, komt in
                  afwijking van het in lid 1 bepaalde de overeenkomst pas tot
                  stand, indien Motus People aan de Inlener bericht heeft met
                  deze afwijkingen van de offerte in te stemmen.
                </p>
              </li>
              <li data-list-text="3.">
                <p style="text-align: justify">
                  Uitsluitend Motus People geldt tegenover de Inlener of
                  Gebruiker als contractuele wederpartij.
                </p>
              </li>
              <li data-list-text="4.">
                <p style="text-align: justify">
                  Motus People behoudt het recht, zonder opgaaf van redenen, een
                  overeenkomst af te wijzen.
                </p>
              </li>
              <li data-list-text="5.">
                <p style="text-align: justify">
                  Motus People is niet gebonden aan mondelinge afspraken als
                  deze niet schriftelijk door Motus People zijn bevestigd.
                </p>
              </li>
              <li data-list-text="6.">
                <p style="text-align: justify">
                  De Inleenovereenkomst die voor bepaalde tijd is aangegaan,
                  eindigt van rechtswege aan het einde van de periode waarvoor
                  zij is aangegaan. Deze overeenkomst kan niet tussentijds
                  worden opgezegd, tenzij partijen schriftelijk anders
                  overeenkomen;
                </p>
              </li>
              <li data-list-text="7.">
                <p style="text-align: justify">
                  De Inleenovereenkomst voor onbepaalde tijd kan te allen tijde
                  worden beëindigd door middel van schriftelijke opzegging,
                  tenzij partijen schriftelijk anders overeenkomen. Bij
                  opzegging van de overeenkomst geldt een minimale opzegtermijn
                  van één kalendermaand. Bij opzegging van een Opdracht waarbij
                  de Terbeschikkingstelling zes maanden of langer heeft geduurd,
                  geldt een minimale opzegtermijn van één kalendermaand.
                  Opzegging dient te geschieden per aangetekend schrijven.
                </p>
              </li>
            </ol>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <h1 style="text-indent: 0pt; text-align: left">
              Artikel 5 – Wijziging van de opdracht
            </h1>
          </li>
          <li data-list-text="3.">
            <p style="text-align: justify">
              Motus People heeft het recht wijzigingen aan te brengen in de
              oorspronkelijke Inleenovereenkomst. Indien tijdens uitvoering van
              de Inleenovereenkomst blijkt dat het noodzakelijk is om de
              overeenkomst te wijzigen of aan te vullen, dan zal Motus People
              Inlener hierover tijdig informeren. Het onverminderd gebruik maken
              van de werkzaamheden van Motus People heeft te gelden als
              aanvaarding van de wijzigingen.
            </p>
          </li>
          <li data-list-text="4.">
            <p style="text-align: justify">
              Alle wijzigingen ten gevolge van de invoering van een nieuwe CAO
              en/of andere wet- en regelgeving dienen door Motus People te
              worden toegepast en zullen worden opgenomen/gewijzigd in de
              overeenkomst en zullen aan de Inlener worden doorbelast. Dit geldt
              tevens voor overige (toekomstige) wet en cao wijzigingen.
            </p>
          </li>
          <li data-list-text="5.">
            <p style="text-align: justify">
              Inlener stemt er, door acceptatie van deze voorwaarden, op
              voorhand mee in dat deze Algemene Voorwaarden eenzijdig kunnen
              worden gewijzigd naar aanleiding van de nieuwe CAO en/of andere
              wet- en regelgeving.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <h1 style="text-indent: 0pt; text-align: left">
              Artikel 6 – De Terbeschikkingstelling
            </h1>
          </li>
          <li data-list-text="6.">
            <p style="text-align: justify">
              De afspraken die tussen de Inlener en Motus People gelden over de
              terbeschikkingstelling van de
            </p>
            <p style="padding-top: 4pt; text-indent: 0pt; text-align: justify">
              Uitzendkracht, worden vastgelegd in de Inleenovereenkomst. In de
              Inleenovereenkomst wordt de duur van de Terbeschikkingstelling van
              de uitzendkracht vermeld en wanneer deze op voorhand nog niet
              duidelijk is, een zo nauwkeurig mogelijke schatting daarvan. Voor
              zover mogelijk en wenselijk worden daarin verder de begin- en
              einddatum van de terbeschikkingstelling, het aantal te werken
              uren, de opzegtermijn en de arbeidsvoorwaarden van de
              uitzendkracht vastgelegd;
            </p>
          </li>
          <li data-list-text="7.">
            <p style="text-align: justify">
              De Terbeschikkingstelling vangt aan op de datum genoemd in de
              Opdracht. Bij gebreke van een (schriftelijke) Opdracht wordt de
              Terbeschikkingstelling geacht te zijn aangegaan op de datum dat de
              Uitzendkracht zijn werkzaamheden bij Inlener aanvangt.
            </p>
          </li>
          <li data-list-text="8.">
            <p style="text-align: justify">
              Motus People is gerechtigd, maar niet verplicht, om bij
              beëindiging van de Terbeschikkingstelling gedurende de looptijd
              van de Inleenovereenkomst, een vervangende Uitzendkracht ter
              beschikking te stellen voor de resterende duur van de
              Inleenovereenkomst.
            </p>
          </li>
          <li data-list-text="9.">
            <p style="text-align: justify">
              Ingeval van beëindiging van de Terbeschikkingstelling, om welke
              reden dan ook, is Motus People niet gehouden voor vervanging van
              de Uitzendkracht zorg te dragen.
            </p>
          </li>
          <li data-list-text="10.">
            <p style="text-align: justify">
              Motus People schiet niet toerekenbaar tekort jegens Inlener en is
              niet gehouden tot vergoeding van enige schade of kosten aan
              Inlener, indien Motus People een Uitzendkracht weliswaar mag
              vervangen of terugplaatsen, maar deze om welke reden dan ook niet
              vervangt of terug plaatst. In dat geval is Inlener ook niet
              gehouden het Inlenerstarief te voldoen, tenzij de oorzaak voor het
              niet vervangen of terugplaatsen van de Uitzendkracht toerekenbaar
              is aan Inlener.
            </p>
            <p style="text-indent: 0pt; text-align: left"><br /></p>
            <h1 style="text-indent: 0pt; text-align: left">
              Artikel 7 – Arbeidsovereenkomst met de Uitzendkracht
            </h1>
          </li>
          <li data-list-text="11.">
            <p style="text-align: justify">
              De Inlener, die voornemens is een arbeidsverhouding met de
              Uitzendkracht aan te gaan, brengt Motus People hiervan tijdig
              schriftelijk op de hoogte alvorens Inlener aan dit voornemen
              uitvoering geeft.
            </p>
          </li>
          <li data-list-text="12.">
            <p style="text-align: justify">
              Inlener zal geen arbeidsverhouding met een Uitzendkracht aangaan
              zolang de Uitzendovereenkomst tussen de Uitzendkracht en Motus
              People niet rechtsgeldig is beëindigd.
            </p>
          </li>
          <li data-list-text="13.">
            <p style="text-align: justify">
              Eerst na afloop van de Inleenovereenkomst is Inlener gerechtigd de
              Uitzendkracht van Motus People over te nemen en zelf een
              arbeidsovereenkomst met hem te sluiten. Het is Inlener niet
              toegestaan voor afloop van de Inleenovereenkomst rechtstreeks een
              arbeidsovereenkomst met de Uitzendkracht te sluiten, of deze
              anderszins voor hem werkzaamheden te laten verrichten.
            </p>
          </li>
          <li data-list-text="14.">
            <p style="text-align: justify">
              Inlener dient Motus People minimaal één kalendermaand voor de
              beoogde overname te informeren over de voorgenomen overname van de
              Uitzendkracht.
            </p>
          </li>
          <li data-list-text="15.">
            <p style="text-align: justify">
              Het is Inlener niet toegestaan om de Uitzendkracht te benaderen
              teneinde hem te bewegen bij Inlener in dienst te treden, zulks
              voor een periode van zes maanden nadat Inlener de Uitzendkracht
              via Motus People heeft afgewezen of de Uitzending heeft beëindigd
              voor einde Inleenovereenkomst.
            </p>
          </li>
          <li data-list-text="16.">
            <p style="text-align: left">
              Voor de toepassing van dit artikel wordt onder het aangaan van een
              arbeidsverhouding mede verstaan het:
            </p>
            <ul id="l6">
              <li data-list-text="">
                <p style="text-align: left">
                  sluiten van een arbeidsovereenkomst voor hetzelfde of ander
                  werk;
                </p>
              </li>
              <li data-list-text="">
                <p style="text-align: left">
                  sluiten van een overeenkomst van opdracht voor hetzelfde of
                  ander werk;
                </p>
              </li>
              <li data-list-text="">
                <p style="text-align: left">
                  sluiten van een overeenkomst tot aanneming van hetzelfde of
                  ander werk;
                </p>
              </li>
              <li data-list-text="">
                <p style="text-align: left">
                  aanstellen als ambtenaar voor hetzelfde of ander werk;
                </p>
              </li>
              <li data-list-text="">
                <p style="padding-top: 4pt; text-align: justify">
                  ter beschikking laten stellen van de betreffende werknemer aan
                  de inlener door een derde voor hetzelfde of ander werk en;
                </p>
              </li>
              <li data-list-text="">
                <p style="text-align: left">
                  aangaan van enige arbeidsverhouding door de werknemer met een
                  derde voor hetzelfde of ander werk, waarbij de inlener en die
                  derde in een groep zijn verbonden dan wel de één een
                  dochtervennootschap is van de ander.
                </p>
              </li>
            </ul>
          </li>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 8 – Uitvoering van de overeenkomst
          </h1>
          <ol id="l7">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Inlener draagt er zorg voor dat alle gegevens, waaronder, maar
                niet beperkt tot, informatie en documentatie, waarvan Motus
                People aangeeft dat deze noodzakelijk zijn of waarvan Inlener
                redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn
                voor het uitvoeren van de Inleenovereenkomst, tijdig aan Motus
                People worden verstrekt.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Indien de voor de uitvoering van de Overeenkomst benodigde
                gegevens niet tijdig aan Motus People zijn verstrekt, heeft
                Motus People het recht de uitvoering van de Inleenovereenkomst
                op te schorten en/of de uit de vertraging voortvloeiende extra
                kosten volgens de dan geldende uurtarieven van Motus People aan
                Inlener in rekening te brengen.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Motus People is niet aansprakelijk voor schade, van welke aard
                ook, doordat Motus People is uitgegaan van door de Inlener
                verstrekte onjuiste en / of onvolledige gegevens, tenzij deze
                onjuistheid of onvolledigheid voor Motus People kenbaar behoorde
                te zijn.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Op de dienstverlening is (al dan niet indirect) de vigerende cao
                ABU van toepassing.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                Motus People is gerechtigd bij de uitvoering van de opdracht
                derden in te schakelen indien zij dat noodzakelijk acht. Motus
                People is niet aansprakelijk voor eventuele tekortkomingen van
                deze derde(n).
              </p>
            </li>
            <li data-list-text="6.">
              <p style="text-align: justify">
                De Inlener geeft Motus People op voorhand toestemming om
                gegevens die voor deze derde(n) van belang zijn aan hen te
                overleggen.
              </p>
            </li>
            <li data-list-text="7.">
              <p style="text-align: justify">
                Een door Motus People opgegeven termijn voor het volbrengen van
                de Overeenkomst heeft een indicatieve strekking en betreft
                derhalve geen fatale termijn, tenzij uit de aard of de inhoud
                van de Overeenkomst anders blijkt.
              </p>
            </li>
            <li data-list-text="8.">
              <p style="text-align: justify">
                Indien Motus People op verzoek van Inlener andere en daarmee
                extra werkzaamheden (meerwerk) moet verrichten die buiten de
                Inleenovereenkomst vallen, zal Motus People deze werkzaamheden
                op basis van nacalculatie aan de hand van de op dat moment
                geldende uurtarieven aan Inlener factureren, tenzij schriftelijk
                anders overeengekomen.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 9 – Verplichtingen van Inlener
          </h1>
          <ol id="l8">
            <li data-list-text="1.">
              <p style="text-align: left">
                Inlener verklaart zich bekend met het feit dat hij in de
                Arbeidsomstandighedenwet wordt aangemerkt als werkgever.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: left">
                Inlener zal de lokalen, werktuigen en gereedschappen waarin of
                waarmee hij de Uitzendkracht werkzaamheden laat verrichten,
                zodanig inrichten en onderhouden en voor het verrichten van de
                werkzaamheden van de Uitzendkracht zodanige maatregelen treffen
                en aanwijzingen verstrekken als redelijkerwijs nodig is om te
                voorkomen dat de Uitzendkracht bij het verrichten van zijn
                werkzaamheden in de ruimste zin des woords schade lijdt. Inlener
                verklaart zich uitdrukkelijk bekend met de werking van art.
                7:658 BW (in het bijzonder art. 7:658 lid 4 BW), alsmede met de
                verplichtingen die voor hem uitvloeien uit de
                Arbeidsomstandighedenwet en daarmee samenhangende regelgeving
                ten opzichte van de Uitzendkrachten.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: left">
                Inlener zal aan Motus People en ook aan de Uitzendkracht voor
                aanvang van de uitzendarbeid
              </p>
              <p style="padding-top: 4pt; text-indent: 0pt; text-align: left">
                informatie verstrekken over de van de Uitzendkracht verlangde
                beroepskwalificatie, alsmede een document bevattende de
                specifieke kenmerken van de in te nemen arbeidsplaats. Inlener
                geeft de Uitzendkracht actieve voorlichting met betrekking tot
                de binnen zijn onderneming gehanteerde risico-inventarisatie en
                -evaluatie (RI&amp;E).
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: left">
                Indien de Uitzendkracht een bedrijfsongeval of een beroepsziekte
                overkomt (art. 7:658 BW), zal Inlener, indien en voor zover de
                wet dit voorschrijft, de bevoegde instanties hiervan onverwijld
                op de hoogte stellen en ervoor zorgdragen dat daarvan direct een
                rapport wordt opgemaakt. In dit rapport zal de toedracht van het
                ongeval zodanig worden vastgelegd, dat daaruit met een redelijke
                mate van zekerheid kan worden opgemaakt of en in hoeverre het
                ongeval het gevolg is van het feit dat onvoldoende maatregelen
                waren genomen ter voorkoming van een dergelijk bedrijfsongeval
                of een dergelijke beroepsziekte. Inlener brengt Motus People zo
                spoedig mogelijk op de hoogte van een eventueel bedrijfsongeval
                of van een beroepsziekte en verstrekt aan Motus People een
                afschrift van het rapport.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: left">
                Indien de Uitzendkracht overlijdt of (ernstig en blijvend)
                letsel oploopt, zal de Inlener met inachtneming van het bepaalde
                in artikel 6:107 tot en met 6:108 BW de schade vergoeden aan de
                in deze artikelen bedoelde persoon of personen.
              </p>
            </li>
            <li data-list-text="6.">
              <p style="text-align: left">
                Inlener zal aan de Uitzendkracht alle schade vergoeden die de
                Uitzendkracht in het kader van de uitoefening van de
                werkzaamheden lijdt, indien en voor zover de Inlener en/of Motus
                People daarvoor aansprakelijk is.
              </p>
            </li>
            <li data-list-text="7.">
              <p style="text-align: justify">
                Inlener vrijwaart Motus People voor alle op dit artikel
                gebaseerde vorderingen of aanspraken van de Uitzendkracht of
                derden. Inlener zal zich afdoende verzekeren tegen
                aansprakelijkheid op grond van het bepaalde in dit artikel. Op
                verzoek van Motus People verstrekt de Inlener een bewijs van
                verzekering.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 10 – Vertrouwelijkheid
          </h1>
          <ol id="l9">
            <li data-list-text="1.">
              <p style="text-align: justify">
                De Inlener verplicht zich om alle gegevens en stukken, die
                betrekking hebben op de Inleenovereenkomst, welke in
                redelijkheid hebben te gelden als vertrouwelijk en geheim, als
                zodanig te behandelen. De Motus People is niet aansprakelijk
                voor schending van de geheimhouding door de personen in zijn
                dienst, indien de Motus People aannemelijk kan maken dat deze
                schending niet door hem verhinderd had kunnen worden.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Inlener zal voldoen aan zijn informatieplicht jegens
                Uitzendkrachten, en Uitzendkrachten in ieder geval op de hoogte
                stellen van de categorieën van persoonsgegevens die door Inlener
                worden verwerkt, de wijze waarop, wanneer en voor welke
                doeleinden de gegevens worden verwerkt. Inlener vrijwaart Motus
                People voor eventuele hieruit voortvloeiende schade of
                aansprakelijkheid, met inbegrip van boetes.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: left">
                Daar waar Inlener eigen verantwoordelijkheid draagt voor de
                verwerking van persoonsgegevens van Uitzendkrachten, is Inlener
                nadrukkelijk zelf verantwoordelijk voor de naleving van zijn
                wettelijke verplichtingen in het kader van geldende
                privacywetgeving. Inlener vrijwaart Motus People voor eventuele
                hieruit voortvloeiende schade of aansprakelijkheid, met inbegrip
                van boetes.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: left">
                Indien noodzakelijk voor Motus People om te kunnen voldoen aan
                wettelijke verplichtingen op basis van geldende
                privacywetgeving, verleent Inlener daartoe volledige medewerking
                aan Motus People, zoals wanneer een Uitzendkracht een verzoek
                indient ter uitoefening van zijn of haar recht op verwijdering.
              </p>
            </li>
          </ol>
          <h1 style="padding-top: 4pt; text-indent: 0pt; text-align: left">
            Artikel 11 - Facturatie en betaling
          </h1>
          <ol id="l10">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Tenzij anders vermeld, zijn de door Motus People
              </p>
              <p style="text-indent: 0pt; text-align: justify">
                opgegeven prijzen uitgedrukt in euro’s.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Tenzij anders overeengekomen, zijn de afspraken over beloning en
                facturatie zoals opgenomen in de Inleenovereenkomst leidend.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Motus People is gerechtigd het de geldende uurtarieven per 1
                januari van elk jaar aan te passen. Vanaf het tijdstip waarop de
                nieuwe tarieven gelden, zullen ook in lopende zaken vanaf dat
                moment de nieuwe tarieven in rekening worden gebracht.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Tariefwijzingen ten gevolge van cao-verplichtingen en
                wijzigingen in of ingevolge van wet- en regelgeving zoals
                fiscale en sociale wet- en regelgeving, worden met ingang van
                het tijdstip van die wijzingen aan de inlener doorberekend en
                zijn dienovereenkomstig door de inlener verschuldigd, ook als
                deze wijzingen zich voordoen tijdens de duur van een
                inleenovereenkomst.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                De betaaltermijn van facturen van Motus People bedraagt 14 dagen
                na de factuurdatum, tenzij anders overeengekomen.
              </p>
            </li>
            <li data-list-text="6.">
              <p style="text-align: justify">
                Uitsluitend betalingen aan de werkgever werken bevrijdend.
                Betalingen aan de werknemer of het verstrekken van voorschotten
                aan de werknemer zijn onverbindend.
              </p>
            </li>
            <li data-list-text="7.">
              <p style="text-align: justify">
                Eventueel bezwaar tegen de hoogte van een factuur dient binnen
                14 dagen na de factuurdatum schriftelijk aan de werkgever
                gemotiveerd kenbaar te worden gemaakt. Indien bezwaar of de
                motivatie daarvan binnen de gestelde termijn uitblijft, wordt de
                inlener geacht met de hoogte van de factuur in te stemmen.
                Bezwaar ontslaat de inlener niet van zijn verplichting de
                factuur te voldoen, tenzij de werkgever dit schriftelijk
                bevestigt.
              </p>
            </li>
            <li data-list-text="8.">
              <p style="text-align: justify">
                Indien de Inlener niet binnen deze termijn de volledige factuur
                betaalt, zal Motus People de Inlener een eenmalige
                betalingsherinnering sturen, met daarin een termijn van 14 dagen
                om de factuur alsnog te betalen.
              </p>
            </li>
            <li data-list-text="9.">
              <p style="text-align: justify">
                Wordt niet binnen de gestelde betalingstermijn voldaan, of wordt
                de incasso zonder geldige reden geannuleerd en niet binnen 7
                dagen alsnog betaald, is Inlener van rechtswege in gebreke en
                wettelijke rente verschuldigd over de openstaande factuur,
                zonder dat enige aanmaning of ingebrekestelling nodig zal zijn,
                onverminderd het recht van Motus People om het verschuldigde
                bedrag met rente en kosten van buitengerechtelijke of
                gerechtelijke invordering terstond op te vorderen. De kosten van
                buitengerechtelijke invordering worden tussen partijen
                vastgesteld door middel van het per 1 juli 2012 ingetreden
                Besluit vergoeding buitenrechtelijke incassokosten;
              </p>
            </li>
            <li data-list-text="10.">
              <p style="text-align: justify">
                Indien de Inlener in verzuim is te betalen, is Motus People het
                recht de Terbeschikkingstelling met onmiddellijke ingang te
                beëindigen, zonder dat zij aansprakelijk is voor eventuele
                schade als gevolg daarvan. Eventuele gerechtelijke en
                buitengerechtelijke kosten die de werkgever moet maken voor de
                incasso van haar vorderingen komen geheel voor rekening van de
                inlener.
              </p>
            </li>
            <li data-list-text="11.">
              <p style="text-align: justify">
                Het bovenstaande geldt onverminderd het recht van Motus People
                om de overeenkomst te ontbinden en schadevergoeding te vorderen.
              </p>
            </li>
            <li data-list-text="12.">
              <p style="text-align: justify">
                Alle kosten die Motus People maakt in verband met niet tijdige
                betaling door de Inlener, zijn voor rekening van de Inlener.
              </p>
            </li>
            <li data-list-text="13.">
              <p style="text-align: justify">
                Indien een factuur, ondanks aanmaning, niet (volledig) wordt
                voldaan, is Motus People gerechtigd haar werkzaamheden op te
                schorten of te beëindigen, nadat de Inlener daarvan schriftelijk
                in kennis is gesteld. Motus People is niet aansprakelijk voor
                schade die mocht ontstaan als gevolg van deze
              </p>
              <p
                style="padding-top: 4pt; text-indent: 0pt; text-align: justify"
              >
                opschorting of beëindiging van de werkzaamheden op deze grond.
              </p>
            </li>
            <li data-list-text="14.">
              <p style="text-align: justify">
                Indien de kredietwaardigheid van Inlener naar het oordeel van
                Motus People daartoe aanleiding geeft, kan Motus People
                verlangen dat Inlener ten gunste van Motus People adequate
                zekerheid stelt tot nakoming door Inlener van haar
                verplichtingen uit hoofde van de Overeenkomst van Opdracht, bij
                gebreke waarvan Motus People de uitvoering van de overeenkomst
                mag opschorten tot Inlener naar het oordeel van Motus People aan
                deze verplichting heeft voldaan.
              </p>
            </li>
            <li data-list-text="15.">
              <p style="text-align: justify">
                In geval van liquidatie, faillissement, beslag of surseance van
                betaling van de Inlener zijn de vorderingen van Motus People op
                de Inlener onmiddellijk opeisbaar.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 12 – Overmacht
          </h1>
          <ol id="l11">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Motus People is niet gehouden tot het nakomen van enige
                verplichting jegens de Inlener indien hij daartoe gehinderd
                wordt als gevolg van een omstandigheid die niet is te wijten aan
                schuld, en noch krachtens de wet, een rechtshandeling, of wegens
                in het verkeer geldende opvattingen voor zijn rekening komt.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Onder overmacht wordt in deze algemene voorwaarden verstaan,
                naast hetgeen daaromtrent in de wet en jurisprudentie wordt
                begrepen, alle van buiten komende oorzaken, voorzien of
                onvoorzien, waarop Motus People geen invloed kan uitoefenen,
                doch waardoor Motus People niet in staat is zijn verplichtingen
                na te komen (o.a. werkstaking, bedrijfsbezetting, blokkades,
                embargo, overheidsmaatregelen, oorlog, revolutie en/of enig
                daaraan gelijk te stellen toestand, stroomstoringen, storingen
                in elektronische communicatielijnen, brand, ontploffing en
                andere calamiteiten, waterschade, overstroming, aardbeving en
                andere natuurrampen, alsmede omvangrijke ziekte van
                epidemiologische aard van personeel).
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Motus People heeft ook het recht zich op overmacht te beroepen,
                indien de omstandigheid die (verdere) nakoming van de
                overeenkomst verhindert, intreedt nadat Motus People haar
                verbintenis had moeten nakomen.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Motus People heeft het recht, gedurende de periode dat de
                overmacht duurt, zijn verplichtingen uit de overeenkomst op te
                schorten.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                Beide partijen hebben het recht de overeenkomst te ontbinden,
                indien de overmacht situatie langer dan twee maanden duurt.
              </p>
            </li>
            <li data-list-text="6.">
              <p style="text-align: justify">
                Voor zover Motus People ten tijde van het intreden van overmacht
                haar verplichtingen uit de overeenkomst inmiddels gedeeltelijk
                is nagekomen of deze zal kunnen nakomen en aan het nagekomen
                respectievelijk na te komen gedeelte zelfstandige waarde
                toekomt, is Motus People gerechtigd om het reeds nagekomen
                respectievelijk na te komen gedeelte separaat te factureren. De
                bezoeker is gehouden deze factuur te voldoen als dat er sprake
                is van een afzonderlijke overeenkomst.
              </p>
            </li>
            <li data-list-text="7.">
              <p style="text-align: justify">
                Motus People is niet aansprakelijk voor schade die als gevolg
                van een overmacht situatie zoals in het voorgaande bedoeld.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 13 – Opschorting en ontbinding
          </h1>
          <ol id="l12">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Motus People is bevoegd de nakoming van de verplichtingen op te
                schorten of de overeenkomst te ontbinden, indien:
              </p>
              <ol id="l13">
                <li data-list-text="a.">
                  <p style="text-align: justify">
                    Inlener de verplichtingen uit de Inleenovereenkomst niet of
                    niet volledig nakomt.
                  </p>
                </li>
                <li data-list-text="b.">
                  <p style="text-align: justify">
                    Na het sluiten van de overeenkomst, Motus People ter kennis
                    gekomen omstandigheden goede grond geven te
                  </p>
                  <p
                    style="
                      padding-top: 4pt;
                      text-indent: 0pt;
                      text-align: justify;
                    "
                  >
                    vrezen dat de Inlener de verplichtingen niet zal nakomen.
                  </p>
                </li>
                <li data-list-text="c.">
                  <p style="text-align: justify">
                    In geval er goede grond bestaat te vrezen dat de Inlener
                    slechts gedeeltelijk of niet behoorlijk zal nakomen, is de
                    opschorting slechts toegelaten voor zover de tekortkoming
                    haar rechtvaardigt.
                  </p>
                </li>
                <li data-list-text="d.">
                  <p style="text-align: justify">
                    Inlener bij het sluiten van de overeenkomst verzocht is
                    zekerheid te stellen voor de voldoening van zijn
                    verplichtingen uit de overeenkomst en deze zekerheid
                    uitblijft of onvoldoende is.
                  </p>
                </li>
              </ol>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Op het tijdstip, waarop Inlener in staat van faillissement wordt
                verklaard, voorlopige surseance van betaling aanvraagt, of een
                verzoek van Inlener, natuurlijk persoon, tot het van toepassing
                verklaren van de wettelijke schuldsanering door de rechtbank
                wordt ingewilligd, of Inlener door beslaglegging, onder curatele
                stelling of anderszins de beschikkingsbevoegdheid over zijn
                vermogen of delen ervan verliest, of indien Inlener kennelijk
                buiten staat geraakt zijn financiële verplichtingen na te komen,
                heeft Motus People het recht iedere overeenkomst met Inlener
                zonder rechterlijke tussenkomst ontbonden te verklaren.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Indien er gerede twijfel bij Motus People bestaat omtrent de
                betalingscapaciteit van Inlener, is Motus People bevoegd het
                leveren van producten uit te stellen, totdat Inlener zekerheid
                voor de betaling heeft verschaft. Inlener is aansprakelijk voor
                de door Motus People voor deze vertraagde aflevering te lijden
                directe- en indirecte schade.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Voorts is Motus People bevoegd de overeenkomst te ontbinden
                indien zich omstandigheden voordoen welke van dien aard zijn dat
                nakoming van de overeenkomst onmogelijk is of indien er zich
                anderszins omstandigheden voordoen die van dien aard zijn dat
                ongewijzigde instandhouding van de overeenkomst in redelijkheid
                niet van Motus People kan worden gevergd.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                Door de ontbinding worden de over en weer bestaande vorderingen
                onmiddellijk opeisbaar. Inlener is aansprakelijk voor de door
                Motus People geleden schade.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 14 – Intellectueel eigendom
          </h1>
          <ol id="l14">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Niets uit deze voorwaarden, dan wel een Inleenovereenkomst kan
                worden opgevat als een overdracht van enig intellectueel
                eigendomsrecht. Het intellectueel eigendom ten aanzien van
                Software, gebruikte logo&#39;s, (beeld-) merken, handelsnamen,
                etc. berust bij Motus People en zal ook bij Motus People blijven
                berusten.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Motus People is gerechtigd het woord en/of beeldmerk van Inlener
                zonder vooroverleg met Inlener te gebruiken in uitingen van
                publicitaire aard waaronder mede begrepen maar niet uitsluitend
                advertenties, reclamecampagnes en Internet.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 15 - Aansprakelijkheid
          </h1>
          <ol id="l15">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Behoudens de wettelijke aansprakelijkheid op grond van
                dwingendrechtelijke bepalingen is Motus People nimmer
                aansprakelijk voor enige schade van de Inlener.
                Aansprakelijkheid voor indirecte schade, gevolgschade,
                immateriële schade, bedrijfsschade, gederfde winst dan wel
                schade als gevolg van
              </p>
              <p
                style="padding-top: 4pt; text-indent: 0pt; text-align: justify"
              >
                aansprakelijkheid jegens derden, wordt voorts uitdrukkelijk
                uitgesloten.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                De leiding en het toezicht over het werk van de flexkracht en de
                werkomstandigheden liggen bij de Inlener. Motus People heeft
                hierop geen invloed. Dit brengt mee dat Inlener verantwoordelijk
                is voor dit werk en voor de veiligheid op de werkplek. De
                Inlener is in het verlengde hiervan aansprakelijk als zich
                schade voordoet tijdens het werken door de Flexkracht ten
                behoeve van de Inlener en de Inlener vrijwaart Motus People
                hiervoor<span class="s1">.</span>
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Motus People is, voor zover nodig in afwijking van het bepaalde
                in lid 1, niet aansprakelijk voor enige schade ten gevolge van
                onjuiste selectie van een Uitzendkracht, tenzij Inlener binnen
                zeven kalenderdagen na aanvang van de Terbeschikkingstelling een
                schriftelijke klacht terzake bij Motus People indient en daarbij
                aantoont dat de onjuiste selectie het rechtstreekse gevolg is
                van opzet of bewuste roekeloosheid aan de zijde van Motus
                People.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Indien en voor zover, ondanks het hierboven gestelde op Motus
                People enige aansprakelijkheid rust, uit welke hoofde dan ook,
                is de aansprakelijkheid van Motus People te allen tijde in haar
                totaliteit beperkt tot het bedrag dat in dat desbetreffende
                geval door de beroepsaansprakelijkheidsverzekering wordt
                uitbetaald, te vermeerderen met het bedrag aan eigen risico dat
                volgens de geldende polisvoorwaarden voor rekening van Motus
                People komt.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                Indien de beroepsaansprakelijkheidsverzekering, om wat voor
                reden dan ook, niet tot uitkering overgaat, is de
                aansprakelijkheid van Motus People beperkt tot een bedrag van
                maximaal driemaal de vergoeding zonder omzetbelasting die de
                Inlener verschuldigd is of zou zijn voor die (deel)opdracht(en),
                waarvan de werkzaamheden onderdeel uitmaken die tot de schade
                hebben geleid, tot een maximum van € 20.000,00.
              </p>
            </li>
            <li data-list-text="6.">
              <p style="text-align: justify">
                Motus People is niet aansprakelijk voor indirecte schade,
                waaronder nadrukkelijk, doch niet uitsluitend, wordt begrepen:
                gevolgschade, immateriële schade, vermogensschade en
                letselschade.
              </p>
            </li>
            <li data-list-text="7.">
              <p style="text-align: justify">
                De Inlener vrijwaart Motus People van alle mogelijke aanspraken
                van derden die verband houden met de door Motus People
                uitgevoerde werkzaamheden ten behoeve van de Inlener.
              </p>
            </li>
            <li data-list-text="8.">
              <p style="text-align: justify">
                Elke aanspraak die de Inlener meent te hebben op Motus People
                vervalt in elk geval na verloop van 12 maanden na de gebeurtenis
                die de schade heeft veroorzaakt is ontdekt of redelijkerwijs had
                moeten zijn ontdekt.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 16 - Klachten
          </h1>
          <ol id="l16">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Klachten over de verrichte werkzaamheden dienen door de Inlener
                binnen 7 dagen na ontdekking, doch uiterlijk binnen 30 dagen na
                voltooiing van de betreffende werkzaamheden schriftelijk te
                worden gemeld aan Motus People, bij de door u bekende
                contactpersoon. De ingebrekestelling dient een zo gedetailleerd
                mogelijke omschrijving van de tekortkoming te bevatten, zodat
                Motus People in staat is adequaat te reageren.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Na verloop van de termijnen in lid 1 genoemd vervalt elke
                aansprakelijkheid van Motus People terzake.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Een klacht schort de betalingsverplichting niet op.
              </p>
            </li>
            <li data-list-text="4.">
              <p style="text-align: justify">
                Bewijs van de gegrondheid van de klacht ligt bij Inlener.
              </p>
            </li>
            <li data-list-text="5.">
              <p style="text-align: justify">
                Indien een klacht gegrond is, dan heeft Motus People de
                mogelijkheid om het gebrek te herstellen.
              </p>
            </li>
          </ol>
          <h1 style="padding-top: 4pt; text-indent: 0pt; text-align: left">
            Artikel 17 – Onverbindendheid
          </h1>
          <ol id="l17">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Indien een of meer bepalingen van deze Algemene Voorwaarden
                nietig of vernietigbaar zijn of worden, blijven de overige
                bepalingen onverminderd van kracht. Partijen verplichten zich om
                een nietige of vernietigde bepaling te vervangen door een
                bepaling die wel rechtsgeldig is en die zo min mogelijk afwijkt
                van de nietige of vernietigde bepaling.
              </p>
            </li>
          </ol>
          <p style="text-indent: 0pt; text-align: left"><br /></p>
          <h1 style="text-indent: 0pt; text-align: left">
            Artikel 18 - Toepasselijk recht en bevoegde rechter
          </h1>
          <ol id="l18">
            <li data-list-text="1.">
              <p style="text-align: justify">
                Op alle opdrachten en daaruit voortvloeiende overeenkomsten
                tussen Motus People en de Inlener of Gebruiker is uitsluitend
                Nederlands recht van toepassing.
              </p>
            </li>
            <li data-list-text="2.">
              <p style="text-align: justify">
                Indien er een geschil ontstaat tussen Motus People en de Inlener
                of Gebruiker, waar partijen onderling niet uitkomen, dan dient
                het geschil uitsluitend te worden voorgelegd aan de Rechtbank
                Oost-Brabant.
              </p>
            </li>
            <li data-list-text="3.">
              <p style="text-align: justify">
                Indien deze Algemene Voorwaarden zijn opgesteld in meerdere
                talen, is de Nederlandse tekst bindend, mocht een verschil
                tussen de Nederlandse tekst en de tekst in de vreemde taal
                bestaan.
              </p>
            </li>
          </ol>
        </nz-card>
      </div>
    </div>
  </section>

  <div
    class="bg-logo-overlay w-full md:w-4/5 xl:w-3/5 absolute top-0 left-0 h-full"
  ></div>
</div>
