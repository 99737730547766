import { Component, ElementRef, HostListener } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { LanguageListComponent } from '../../components/language-list/language-list.component';
import { ContactFormComponent } from '../../components/contact-form/contact-form.component';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DropDownAnimation } from '../../../../assets/animations/drop-down';
import { element } from 'protractor';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    DropDownAnimation,
    trigger('toggleHeader', [
      state('hidden', style({ transform: 'translateY(-100%)' })),
      state('visible', style({ transform: 'translateY(0)' })),
      transition('hidden => visible', animate('300ms ease-in')),
      transition('visible => hidden', animate('300ms ease-out')),
    ]),
  ],
})
export class HeaderComponent {
  showMenu = false;
  showDrawer = false;
  headerState: 'hidden' | 'visible' = 'visible';
  lastScrollPosition = 0;

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(): void {
    if (
      window.scrollY >
        this.elementRef.nativeElement.childNodes[0].offsetHeight ||
      window.scrollY === 0
    ) {
      const currentScrollPosition = window.pageYOffset;
      if (currentScrollPosition > this.lastScrollPosition) {
        this.headerState = 'hidden';
      } else {
        this.headerState = 'visible';
      }
      this.lastScrollPosition = currentScrollPosition;
    }
  }

  constructor(
    private modalService: NzModalService,
    public deviceService: DeviceDetectorService,
    private elementRef: ElementRef
  ) {}

  public toggleNavbar(): void {
    this.showMenu = !this.showMenu;
  }

  public showLanguageModal(): void {
    this.modalService.create({
      nzContent: LanguageListComponent,
      nzFooter: null,
      nzAutofocus: null,
    });
  }

  public showApplyModal(): void {
    this.modalService.create({
      nzContent: ContactFormComponent,
      nzFooter: null,
      nzCentered: true,
      nzAutofocus: null,
    });
  }

  scrollToTop() {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 350);
  }
}
