import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IVacancy } from '../../../interfaces/data';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

untilDestroyed(this);
@UntilDestroy()
@Component({
  selector: 'app-job-card',
  templateUrl: './job-card.component.html',
  styleUrls: ['./job-card.component.scss'],
})
export class JobCardComponent implements OnInit {
  @Input() job!: IVacancy;
  @Input() gridMode = false;
  jobId = '';

  constructor(private router: Router, public translate: TranslateService) {}

  ngOnInit(): void {
    this.jobId = this.job.id;
  }

  public onClick(): void {
    this.router.navigateByUrl('/job/' + this.jobId);
  }
}
