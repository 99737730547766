<div class="w-full">
  <div class="w-full text-primary-500 text-xl font-semibold pb-5">{{ 'LANGUAGE.CHOOSE' | translate }}</div>
  <div class="flex w-full py-2"
       *ngFor="let lang of translate.getLangs()">
    <div class="w-full p-5 cursor-pointer rounded-2xl border border-gray-200 shadow border-solid py-2 hover:border-blue-400"
         (click)="handleNewLanguage(lang)">
      <nz-avatar [nzSize]="64" nzSrc='/assets/SVG/flags/flag-{{lang}}.svg'></nz-avatar>
      <span class="pl-4 text-xl">{{languageList[lang]}}</span>
    </div>
  </div>
</div>
