<section class="relative">
  <div class="md:flex w-full bg-white">
    <div class="md:flex-1"></div>
    <img
      class="block md:hidden w-full h-48 object-cover"
      src="assets/handshake-min.jpg"
      alt="Handshake"
    />
    <div class="md:flex-1">
      <div
        class="px-6 md:pl-0 md:pr-4 pt-6 pb-12 md:flex flex-wrap content-center w-full h-full max-w-lg relative"
      >
        <div class="w-full flex flex-col gap-6 pr-2 py-8 md:py-20">
          <h1
            class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl font-extrabold"
          >
            Applied successfully!
          </h1>
          <div class="text-base md:text-lg font-light">
            <p>
              Congratulations on completing your application! Now that it's
              submitted, our team will review it thoroughly, and you can expect
              to hear from us soon about its status. Get ready for an exciting
              journey ahead filled with growth, learning, and opportunities.
              Stay tuned for updates and welcome to our community!
            </p>
          </div>
          <div class="md:flex md:text-center">
            <div class="pr-0 md:pr-4 pb-4 md:pb-0">
              <button
                nz-button
                nzType="primary"
                [nzSize]="'large'"
                nzShape="round"
                [routerLink]="['/']"
              >
                Back home
              </button>
            </div>

            <button
              nz-button
              nzType="default"
              [routerLink]="['/about']"
              [nzSize]="'large'"
              nzShape="round"
            >
              Learn more about Motus
            </button>
          </div>
        </div>
        <div
          class="dashed-line-secondary right-line absolute top-0 right-0"
        ></div>
      </div>
    </div>
  </div>
  <div
    class="hidden md:block md:opacity-90 bg-work-us w-1/2 absolute top-0 left-0 h-full"
  ></div>
</section>
