import { Component, Input } from '@angular/core';
import { IBlog } from '../../../interfaces/data';

@Component({
  selector: 'app-news-card',
  templateUrl: './news-card.component.html',
  styleUrls: ['./news-card.component.scss'],
})
export class NewsCardComponent {
  @Input() newsItem!: IBlog;
}
