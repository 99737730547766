<div class="jobs-detail relative py-12 md:py-20">
  <section class="jobs-view">
    <div class="flex flex-wrap container pb-8 md:pb-10">
      <div>
        <h2
          class="text-xl md:text-2xl lg:text-3xl xl:text-4xl text-secondary-500 font-semibold"
        >
          {{
            applyingForMotus ? "Apply for Motus People" : job?.field?.name?.en
          }}
        </h2>
        <h1
          class="h-full text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold mb-4"
        >
          {{ applyingForMotus ? "Become part of our team" : job?.title?.en }}
        </h1>
      </div>
      <div class="w-full flex flex-wrap gap-2 md:gap-4">
        <div
          class="bg-white shadow-lg px-4 py-2 rounded-2xl"
          *ngIf="job?.region"
        >
          <div class="text-lg font-bold font-['Roboto']">
            <i
              class="fa pr-2 fa-map-marker text-secondary-500"
              aria-hidden="true"
            ></i>
            {{
              translate.currentLang === "nl"
                ? job?.region?.name?.nl
                : job?.region?.name?.en
            }}
          </div>
        </div>

        <div
          class="bg-white shadow-lg px-4 py-2 rounded-2xl"
          *ngIf="job?.employment"
        >
          <div class="text-lg font-bold font-['Roboto']">
            <i
              class="fa pr-2 fa-clock-o text-secondary-500"
              aria-hidden="true"
            ></i>
            {{
              translate.currentLang === "nl"
                ? job?.employment?.name?.nl
                : job?.employment?.name?.en
            }}
          </div>
        </div>
        <div
          class="bg-white shadow-lg px-4 py-2 rounded-2xl"
          *ngIf="job?.salaryRange?.min"
        >
          <div class="text-lg font-bold font-['Roboto']">
            <i
              class="fa pr-2 fa-euro text-secondary-500"
              aria-hidden="true"
            ></i>
            <span
              >{{ job?.salaryRange?.min }} - {{ job?.salaryRange?.max }}</span
            >
          </div>
        </div>

        <div
          class="bg-white shadow-lg px-4 py-2 rounded-2xl"
          *ngIf="job?.field"
        >
          <div class="text-lg font-bold font-['Roboto']">
            <i
              class="fa pr-2 fa-suitcase text-secondary-500"
              aria-hidden="true"
            ></i>
            {{
              translate.currentLang === "nl"
                ? job?.field?.name?.nl
                : job?.field?.name?.en
            }}
          </div>
        </div>
      </div>
    </div>

    <div class="container px-0 md:px-6">
      <div class="max-w-4xl">
        <ng-container *ngIf="!loading; else loader">
          <div class="bg-white p-6 md:p-8 rounded-2xl shadow-lg">
            <ng-container *ngIf="!isApplying; else applyForm">
              <div></div>
              <app-job-detail-card [job]="job"></app-job-detail-card
            ></ng-container>
            <ng-template #applyForm>
              <h2 class="text-2xl lg:text-3xl xl:text-4xl font-bold pb-4">
                Let's get to know each other
              </h2>
              <app-contact-form [job]="job" [isApply]="true"></app-contact-form>
            </ng-template>
          </div>
        </ng-container>
        <ng-template #loader>
          <ng-container *ngFor="let index of [0, 1, 2, 3]">
            <nz-skeleton
              class="py-3"
              *ngIf="loading"
              [nzActive]="true"
              [nzTitle]="false"
              [nzLoading]="true"
            ></nz-skeleton>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </section>

  <div
    *ngIf="!applyingForMotus"
    class="container mt-12 flex items-center gap-4"
  >
    <h3 class="font-semibold text-xl lg:text-2xl pr-4">Share this vacancy</h3>
    <a
      href="https://www.linkedin.com/sharing/share-offsite/?url={{
        currentUrl
      }}"
      target="_blank"
    >
      <i class="text-4xl fa fa-linkedin"></i>
    </a>
    <a
      href="https://www.facebook.com/sharer/sharer.php?u=#{{ currentUrl }}"
      target="_blank"
    >
      <i class="text-4xl fa fa-facebook-square"></i>
    </a>
    <a href="https://wa.me/?text={{ currentUrl }}" target="_blank">
      <i class="text-4xl fa fa-whatsapp"></i>
    </a>
  </div>

  <div
    *ngIf="!applyingForMotus"
    class="z-50 mt-12 justify-center bg-gradient-to-r from-secondary-500 to-red-700 items-center sticky bottom-0 w-full py-4 flex gap-4"
  >
    <button
      *ngIf="!isApplying"
      class="px-20 h-10 text-xl md:text-2xl font-['Roboto'] font-bold shadow-2xl"
      nz-button
      nzShape="round"
      (click)="isApplying = true; scrollToTop()"
    >
      Apply now
    </button>
    <button
      *ngIf="isApplying"
      class="px-8 h-10 text-xl md:text-2xl font-['Roboto'] font-bold shadow-2xl"
      nz-button
      nzShape="round"
      (click)="isApplying = false; scrollToTop()"
    >
      <span nz-icon nzType="left"></span>
      Job details
    </button>
    <button
      (click)="scrollToTop()"
      nz-button
      nzSize="large"
      nzShape="circle"
      class="h-12 w-12"
    >
      <span nz-icon nzType="up" nzTheme="outline"></span>
    </button>
  </div>

  <div class="container flex flex-col lg:flex-row gap-6 md:gap-12 mt-12">
    <div
      class="flex flex-col gap-3 w-full h-full bg-white p-6 shadow-xl rounded-xl"
    >
      <div
        class="font-['Roboto'] text-lg md:text-xl lg:text-2xl font-semibold mb-0"
      >
        Check out our way of working
      </div>
      <p>
        If you're curious about our methods and approach, feel free to visit our
        About page. There, you'll discover more about how we operate and what
        sets us apart.
      </p>
      <button
        class="w-fit"
        [routerLink]="['/about']"
        nzType="primary"
        nz-button
      >
        Read more about Motus
      </button>
    </div>
    <div
      class="bg-gradient-to-tr from-secondary-500 to-red-700 gap-4 flex md:gap-8 rounded-xl shadow-xl w-full p-6"
    >
      <img
        class="bg-white object-cover object-top rounded-full h-14 md:h-32 w-14 md:w-32 xl:h-40 xl:w-40"
        src="{{ jobOwner?.image }}"
        alt="{{ jobOwner?.name }}"
      />
      <div class="flex flex-col gap-2 md:gap-4">
        <div>
          <div
            class="font-['Roboto'] text-lg md:text-2xl font-semibold mb-0 text-white"
          >
            {{ jobOwner?.name }}
          </div>
          <div class="md:text-lg text-gray-200">
            {{ jobOwner?.role }}
          </div>
        </div>
        <div class="md:text-lg">
          <div>
            <a class="text-gray-100" href="mailto:{{ jobOwner?.email }}">
              <i class="fa fa-envelope pr-2" aria-hidden="true"></i>
              {{ jobOwner?.email }}
            </a>
          </div>
          <div class="pt-2 pb-4">
            <a class="text-gray-100" href="tel:{{ jobOwner?.phone }}">
              <i class="fa fa-phone pr-2" aria-hidden="true"></i>
              {{ jobOwner?.phone }}
            </a>
          </div>
          <button [routerLink]="['/contact']" nz-button>
            Contact us directly
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg-logo-overlay w-full md:w-4/5 xl:w-3/5 absolute top-0 left-0 h-full"
  ></div>

  <!--  <div-->
  <!--    (click)="scrollToTop()"-->
  <!--    class="hidden md:flex cursor-pointer rounded-full text-3xl w-16 h-16 justify-center items-center fixed bottom-24 right-4 bg-white shadow-2xl hover:bg-gray-50 transition-colors z-50"-->
  <!--  >-->
  <!--    <span nz-icon nzType="up" nzTheme="outline"></span>-->
  <!--  </div>-->
</div>
