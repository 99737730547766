import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { IVacancy } from '../../../interfaces/data';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApplySuccessComponent } from '../apply-components/apply-success/apply-success.component';
import { ApplyErrorComponent } from '../apply-components/apply-error/apply-error.component';
import axios from 'axios';
import { Router } from '@angular/router';
import { ContactSuccessComponent } from './contact-success/contact-success.component';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {
  contactForm: any;
  error: any;
  uploads: NzUploadFile[] = [];
  @Input() isApply = false;
  @Input() isLoading = false;
  @Input() job: IVacancy | undefined;
  autoTips: Record<string, Record<string, string>> = {
    en: {
      required: 'Input is required',
    },
  };
  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NzModalService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      nationality: '',
      remark: '',
      subject: '',
      linkedin: '',
      jobTitle: this.job ? this.job.title.en : 'Open application',
      jobId: this.job ? this.job.id : 'motus-apply',
      emailOwner: this.job ? this.job.owner.email : 'm.kleve@motuspeople.com',
    });
  }

  public onSubmit(): void {
    this.error = '';

    for (const control of Object.values(this.contactForm.controls)) {
      // @ts-ignore
      control.markAsDirty();
      // @ts-ignore
      control.updateValueAndValidity();
    }

    if (!this.contactForm.valid) {
      return;
    }

    const formData: any = new FormData();
    formData.append('firstName', this.contactForm.value.firstName);
    formData.append('lastName', this.contactForm.value.lastName);
    formData.append('email', this.contactForm.value.email);
    formData.append('jobTitle', this.contactForm.value.jobTitle);
    formData.append('jobId', this.contactForm.value.jobId);
    formData.append('emailOwner', this.contactForm.value.emailOwner);
    formData.append('linkedin', this.contactForm.value.linkedin);
    formData.append('nationality', this.contactForm.value.nationality);
    formData.append('phoneNumber', this.contactForm.value.phoneNumber);
    formData.append('remark', this.contactForm.value.remark);
    formData.append('subject', this.contactForm.value.subject);

    formData.append('resume', this.uploads[0]);

    this.isLoading = true;
    if (this.isApply) {
      axios
        .post('https://api.motuspeople.com/mail/application', formData)
        .then(() => this.modalService.closeAll())
        .catch((error) => {
          {
            this.error = error;
            this.openErrorModal();
            console.log(error);
            return;
          }
        })
        .finally(() => {
          if (!this.error) {
            this.router.navigateByUrl('/success');
          }
          this.isLoading = false;
        });
    } else {
      axios
        .post('https://api.motuspeople.com/mail', {
          firstName: this.contactForm.value.firstName,
          lastName: this.contactForm.value.lastName,
          email: this.contactForm.value.email,
          phoneNumber: this.contactForm.value.phoneNumber,
          subject: this.contactForm.value.subject,
          remark: this.contactForm.value.remark,
        })
        .then(() => this.modalService.closeAll())
        .catch((error) => {
          {
            this.error = error;
            this.openErrorModal();
            console.log(error);
            return;
          }
        })
        .finally(() => {
          if (!this.error) {
            if (this.isApply) {
              this.router.navigateByUrl('/success');
            } else {
              this.contactForm.reset();
              this.openSuccessModal();
            }
          }
          this.isLoading = false;
        });
    }
  }

  public openSuccessModal(): void {
    this.modalService.create({
      nzContent: ContactSuccessComponent,
      nzFooter: null,
      nzCentered: true,
      nzAutofocus: null,
    });
  }

  public openErrorModal(): void {
    this.modalService.create({
      nzContent: ApplyErrorComponent,
      nzFooter: null,
      nzCentered: true,
      nzAutofocus: null,
    });
  }

  beforeUpload = (file: NzUploadFile): boolean => {
    this.uploads = this.uploads.concat(file);
    return false;
  };

  emptyUploadList(): void {
    this.uploads = [];
  }
}
