import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { allTeamMembers, officePlaces } from 'src/app/shared/variables';
import { ITeamMember } from '../../../../interfaces/team-member';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss'],
})
export class ContactPageComponent implements OnInit, AfterViewInit {
  protected readonly allTeamMembers = allTeamMembers;
  protected readonly officePlaces = officePlaces;
  @ViewChildren('mapContainer') mapContainers!: QueryList<ElementRef>;

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.setTitle();
  }

  ngAfterViewInit(): void {
    this.mapContainers.forEach((mapContainer, index) => {
      mapContainer.nativeElement.appendChild(this.officePlaces[index].mapsUrl);
    });
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Neem contact op';
    } else {
      title = 'Contact us';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

  membersToContact(): ITeamMember[] {
    return this.allTeamMembers.slice(0, 3);
  }
}
