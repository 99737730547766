<div class="bg-primary-500 text-white relative">
  <div class="container">
    <div class="flex flex-col py-32 md:pb-48">
      <div
        class="pb-16 grid w-fit grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-8 md:gap-16 text-base"
      >
        <div class="flex flex-col gap-4">
          <div class="font-semibold text-xl pb-2 font-['Roboto']">
            Useful links
          </div>
          <a [routerLink]="['']"> Home </a>
          <a [routerLink]="['jobs']">
            {{ "FOOTER.JOBS" | translate }}
          </a>
          <a [routerLink]="['employer']">Work with Motus </a>
          <a [routerLink]="['contact']">
            {{ "FOOTER.CONTACT" | translate }}
          </a>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-semibold text-xl pb-2 font-['Roboto']">
            About Motus
          </div>
          <a [routerLink]="['about']">
            {{ "FOOTER.ABOUT" | translate }}
          </a>
          <a [routerLink]="['family']">
            {{ "FOOTER.FAMILY" | translate }}
          </a>
          <a [routerLink]="['abroad']">Motus Abroad</a>
          <a [routerLink]="['news']">
            {{ "FOOTER.NEWS" | translate }}
          </a>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-semibold text-xl pb-2 font-['Roboto']">Contact</div>
          <div>
            <a href="tel:+31623622416">
              <i class="fa fa-phone pr-2" aria-hidden="true"></i>
              +31 6 2362 2416
            </a>
          </div>
          <div>
            <a href="mailto:info@motuspeople.com">
              <i class="fa fa-envelope pr-2" aria-hidden="true"></i>
              info@motuspeople.com
            </a>
          </div>
          <a [routerLink]="['contact']">
            <i class="fa fa-send pr-2" aria-hidden="true"></i> Contact directly
          </a>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-semibold text-xl pb-2 font-['Roboto']">Address</div>
          <div>Insulindelaan 115</div>
          <div>5642 CV</div>
          <div>Eindhoven</div>
          <div>
            {{ "FOOTER.NETHERLANDS" | translate }}
          </div>
        </div>
        <div class="flex flex-col gap-4">
          <div class="font-semibold text-xl pb-2 font-['Roboto']">Policies</div>
          <a [routerLink]="['privacy-policy']">
            {{ "FOOTER.PRIVACY" | translate }}
          </a>
          <a [routerLink]="['privacy-policy']">
            {{ "FOOTER.COOKIES" | translate }}
          </a>
          <a [routerLink]="['privacy-policy']">
            {{ "FOOTER.TERMS_CONDITIONS" | translate }}
          </a>
        </div>
      </div>

      <hr class="hidden md:block border-gray-50/40" />

      <div
        class="pt-8 flex flex-col gap-4 md:flex-row justify-start md:justify-between"
      >
        <div class="flex flex-col gap-8">
          <a href="/">
            <img
              class="h-full w-48 max-h-16"
              src="./assets/SVG/new-logo.svg"
              alt="motus"
            />
          </a>

          <div class="flex gap-4">
            <img
              class="h-24 w-24 object-contain bg-white rounded-2xl"
              src="./assets/sna-badge.png"
              alt="motus"
            />
            <img
              class="h-24 w-24 object-contain bg-white rounded-2xl"
              src="./assets/vcu-badge.png"
              alt="motus"
            />
          </div>
        </div>

        <div class="flex flex-row gap-4">
          <a href="https://www.instagram.com/motus_people/" target="_blank">
            <i class="text-4xl fa fa-instagram"></i>
          </a>
          <a
            href="https://www.linkedin.com/company/motus-people/"
            target="_blank"
          >
            <i class="text-4xl fa fa-linkedin"></i>
          </a>
        </div>
      </div>
      <div
        class="pt-4 gap-4 flex flex-col md:flex-row justify-start items-start md:items-center md:justify-between"
      >
        <div>© {{ currentYear }} Motus People</div>
        <!--        <div class="hidden md:block">-->
        <!--          <img-->
        <!--            class="h-full w-full max-h-16"-->
        <!--            src="./assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Oranje.svg"-->
        <!--            alt="motus logo orange"-->
        <!--          />-->
        <!--        </div>-->
      </div>
    </div>
  </div>
</div>
