import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IBlog, ITitle } from '../../../../interfaces/data';
import { Observable, Subscription } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';
import { Apollo } from 'apollo-angular';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { AllBlogs, BlogById } from '../../../../graphql/queries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITeamMember } from '../../../../interfaces/team-member';
import { allTeamMembers } from 'src/app/shared/variables';

untilDestroyed(this);
@UntilDestroy()
@Component({
  selector: 'app-news-details-page',
  templateUrl: './news-details-page.component.html',
  styleUrls: ['./news-details-page.component.scss'],
})
export class NewsDetailsPageComponent implements OnInit, OnDestroy {
  public newsId = '';

  public newsItem!: IBlog;
  private querySubscription: Subscription = new Subscription();
  protected readonly allTeamMembers = allTeamMembers;
  author: ITeamMember = this.allTeamMembers[0];

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private apollo: Apollo,
    public translate: TranslateService,
    private titleService: Title
  ) {}

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.route.params
      .pipe(untilDestroyed(this))
      .subscribe(({ newsId = '' }) => {
        this.newsId = newsId;
      });

    this.getBlogs();
  }

  public goBack(): void {
    this.location.back();
  }

  compareJobOwnerToMember(): void {
    const jobOwner = this.newsItem.author;
    const familyMembers = this.allTeamMembers;

    this.author =
      familyMembers.find((teamMember) => teamMember.email === jobOwner.email) ||
      this.allTeamMembers[0];
  }

  private getBlogs(): void {
    this.apollo
      .watchQuery<any>({
        query: BlogById,
        variables: {
          blogId: this.newsId,
        },
      })
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((result) => {
        this.newsItem = result.data.blog;
        this.compareJobOwnerToMember();

        let title = '';
        if (this.translate.currentLang === 'nl') {
          title = this.newsItem.title.nl;
        } else {
          title = this.newsItem.title.en;
        }
        this.titleService.setTitle(title + ' - Motus People');
      });
  }
}
