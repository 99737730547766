<div class="news-detail relative py-12 md:py-20">
  <section class="container flex flex-wrap flex-col pb-4 md:pb-10">
    <div class="w-full pb-8">
      <div
        class="flex flex-col items-start md:items-center md:flex-row gap-4 justify-between"
      >
        <h1
          class="h-full text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-extrabold mb-4"
        >
          {{ newsItem?.title?.en }}
        </h1>
        <div
          class="flex-grow flex justify-center md:justify-end text-lg md:text-2xl text-primary-500 font-semibold font-['Roboto']"
        >
          <i
            class="fa fa-calendar job-icon pr-2 text-secondary-500"
            aria-hidden="true"
          ></i>
          {{ newsItem?.updatedAt | date : "dd/MM/yyyy" }}
        </div>
      </div>
    </div>
    <div class="w-full pb-8 max-w-3xl">
      <nz-card class="job-card rounded-2xl px-0 md:px-3 shadow-lg">
        <p [innerHTML]="newsItem?.description?.en | sanitizeHtml"></p>
      </nz-card>
    </div>

    <div>
      <button
        nz-button
        nzType="primary"
        nzSize="large"
        nzShape="round"
        [routerLink]="['/news']"
      >
        Check out more articles
      </button>
    </div>
  </section>

  <div class="container flex flex-col lg:flex-row gap-6 md:gap-12 pt-16">
    <div
      class="flex flex-col gap-3 w-full h-full bg-white p-4 md:p-6 shadow-xl rounded-xl"
    >
      <div
        class="font-['Roboto'] text-lg md:text-xl lg:text-2xl font-semibold mb-0"
      >
        Check out our way of working
      </div>
      <p>
        If you're curious about our methods and approach, feel free to visit our
        About page. There, you'll discover more about how we operate and what
        sets us apart.
      </p>
      <button
        class="w-fit"
        [routerLink]="['/about']"
        nzType="primary"
        nz-button
      >
        Read more about Motus
      </button>
    </div>
    <div
      class="bg-gradient-to-tr from-secondary-500 to-red-700 gap-4 flex md:gap-8 rounded-xl shadow-xl w-full p-4 md:p-6"
    >
      <img
        class="bg-white object-cover object-top rounded-full h-14 md:h-32 w-14 md:w-32 xl:h-40 xl:w-40"
        src="{{ author?.image }}"
        alt="{{ author?.name }}"
      />
      <div class="flex flex-col gap-2 md:gap-4">
        <div>
          <div
            class="font-['Roboto'] text-lg md:text-xl md:text-2xl font-semibold mb-0 text-white"
          >
            {{ author?.name }}
          </div>
          <div class="md:text-lg text-gray-200">
            {{ author?.role }}
          </div>
        </div>
        <div class="md:text-lg">
          <div>
            <a class="text-gray-100" href="mailto:{{ author?.email }}">
              <i class="fa fa-envelope pr-2" aria-hidden="true"></i>
              {{ author?.email }}
            </a>
          </div>
          <div class="pt-2 pb-4">
            <a class="text-gray-100" href="tel:{{ author?.phone }}">
              <i class="fa fa-phone pr-2" aria-hidden="true"></i>
              {{ author?.phone }}
            </a>
          </div>
          <button [routerLink]="['/contact']" nz-button>
            Contact us directly
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="bg-logo-overlay w-full md:w-4/5 xl:w-3/5 absolute top-0 left-0 h-full"
  ></div>
</div>
