<section class="min-h-screen mx-auto pt-36 bg-gray-100 md:px-8 xl:px-0">
  <div class="flex max-w-screen-lg flex-wrap mx-auto pb-10 px-3 md:px-0">
    <div class="w-full text-center">
      <h2 class="text-3xl text-secondary-500 font-semibold">
        {{ "NOT_FOUND_PAGE.TITLE" | translate }}
      </h2>
      <h1 class="pt-3 text-primary-500 text-4xl font-bold">
        {{ "NOT_FOUND_PAGE.SUBTITLE" | translate }}
      </h1>
    </div>
  </div>
</section>
