<div class="bg-gray-100">
  <section
    class="relative cover bg-fixed bg-gradient-to-br from-secondary-500 to-red-700 overflow-hidden py-48 flex items-center"
  >
    <img
      ngSrc="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
      alt="Motus People logo"
      class="w-full h-full absolute top-0 left-0 object-cover opacity-10"
      height="16"
      width="16"
    />
    <div class="container z-10">
      <h1
        class="text-white text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold"
      >
        {{ "ABOUT_PAGE.SECTION_HOME.TITLE" | translate }}
      </h1>
      <p
        class="max-w-3xl text-lg md:text-2xl font-bold leading-snug mt-4 pb-12 text-gray-100"
      >
        {{ "ABOUT_PAGE.SECTION_HOME.LEADING" | translate }}
      </p>
      <button
        nz-button
        [nzSize]="'large'"
        nzShape="round"
        [routerLink]="['../jobs']"
      >
        Discover our careers
      </button>
    </div>
  </section>
</div>

<section class="relative bg-gray-50">
  <div class="container py-12 md:py-24">
    <div class="flex flex-col gap-8 md:gap-14 md:flex-row items-center">
      <div class="w-full">
        <h2 class="text-3xl md:text-5xl leading-tight font-semibold mt-4">
          {{ "ABOUT_PAGE.SECTION_ONE.TITLE" | translate }}
        </h2>
        <p class="mt-4">
          Motus People specializes in connecting ambitious talents with
          cutting-edge technology companies in Eindhoven and beyond. We focus on
          understanding individuals' aspirations to make personalized career
          matches, aiming to positively impact both professional paths and
          lives. Connect with us to explore how they can elevate your career
          journey.
        </p>
      </div>

      <div class="flex justify-end w-full">
        <img
          class="rounded-xl shadow-lg max-h-80"
          alt="Motus people in a meeting"
          src="assets/motus_meeting.jpeg"
        />
      </div>
    </div>

    <div
      id="card-section"
      class="flex flex-col lg:flex-row mt-24 text-center gap-6"
    >
      <div class="flex-1 bg-white rounded-lg shadow-lg p-6">
        <i
          class="about-info-card-1 pb-3 fa fa-globe text-7xl text-secondary-500"
        ></i>
        <h4 class="text-xl font-bold font-['Roboto']">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_ONE.TITLE" | translate }}
        </h4>
        <p class="mt-1">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_ONE.DESCRIPTION" | translate }}
        </p>
      </div>

      <div class="flex-1 bg-white rounded-lg shadow-lg p-6">
        <i
          class="about-info-card-2 pb-3 fa fa-users text-7xl text-secondary-500"
        ></i>
        <h4 class="text-xl font-bold font-['Roboto']">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_TWO.TITLE" | translate }}
        </h4>
        <p class="mt-1">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_TWO.DESCRIPTION" | translate }}
        </p>
      </div>

      <div class="flex-1 bg-white rounded-lg shadow-lg p-6">
        <i
          class="about-info-card-3 pb-3 fa fa-laptop text-7xl text-secondary-500"
        ></i>
        <h4 class="text-xl font-bold font-['Roboto']">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_THREE.TITLE" | translate }}
        </h4>
        <p class="mt-1">
          {{
            "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_THREE.DESCRIPTION" | translate
          }}
        </p>
      </div>

      <div class="flex-1 bg-white rounded-lg shadow-lg p-6">
        <i
          class="about-info-card-4 pb-3 fa fa-language text-7xl text-secondary-500"
        ></i>
        <h4 class="text-xl font-bold font-['Roboto']">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_FOUR.TITLE" | translate }}
        </h4>
        <p class="mt-1">
          {{ "ABOUT_PAGE.SECTION_ONE.CARDS.CARD_FOUR.DESCRIPTION" | translate }}
        </p>
      </div>
    </div>
  </div>
</section>

<section
  class="relative bg-fixed bg-gradient-to-b from-primary-300 to-primary-900 px-8 py-12 lg:py-32"
>
  <div class="mx-auto max-w-screen-lg">
    <div
      class="flex gap-8 md:gap-12 justify-center items-center flex-col md:flex-row"
    >
      <div class="">
        <div class="flex items-center justify-center">
          <img
            class="w-full h-32 md:h-96"
            src="./assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
            alt="logo"
          />
        </div>
      </div>

      <div class="w-full px-0 lg:px-8">
        <h2 class="text-3xl md:text-5xl font-semibold text-gray-50">
          {{ "ABOUT_PAGE.SECTION_TWO.TITLE" | translate }}
        </h2>
        <p class="mt-4 text-gray-100">
          Motus People is a trusted partner in talent acquisition for the
          Eindhoven region, specializing in connecting ambitious international
          talents with cutting-edge technology companies. Unlike traditional
          recruitment agencies, we prioritize building understanding
          relationships with our clients to address their specific business
          challenges. With a focus on quality and professionalism, we offer
          full-cycle recruitment support and strive to be your dedicated talent
          acquisition partner, providing top-notch staff on both temporary and
          permanent bases.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="relative py-12 md:py-32">
  <div class="container flex flex-col">
    <h3 class="text-3xl md:text-4xl font-semibold">
      Elevating Your Recruitment Experience
    </h3>
    <p class="max-w-3xl mb-8 mt-4">
      Motus People prepares companies for a future driven by globalization and
      talent scarcity. Leading an international network, we find top talents for
      sustainable growth. Let us be your trusted partner in staffing and
      preparing for tomorrow's challenges.
    </p>

    <div class="flex flex-col md:flex-row items-center gap-8">
      <div class="flex flex-col w-full">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8 text-lg mt-4">
          <div class="text-2xl max-w-md font-semibold text-primary-500 py-2">
            <div class="font-['Roboto'] font-semibold">
              Risk-Free Recruitment
            </div>
            <p>
              You only pay upon successful hiring, eliminating upfront costs and
              obligations.
            </p>
          </div>
          <div class="text-2xl max-w-md font-semibold text-primary-500 py-2">
            <div class="font-['Roboto'] font-semibold">Transparent Pricing</div>
            <p>
              Our rates are competitive and straightforward, with no hidden
              fees.
            </p>
          </div>
          <div class="text-2xl max-w-md font-semibold text-primary-500 py-2">
            <div class="font-['Roboto'] font-semibold">
              Seamless Integration
            </div>
            <p>
              We provide comprehensive assistance to international candidates,
              ensuring a smooth localization process and quick integration into
              your team.
            </p>
          </div>
          <div class="text-2xl max-w-md font-semibold text-primary-500 py-2">
            <div class="font-['Roboto'] font-semibold">Cultural Fit</div>
            <p>
              Access international talents to diversify your workforce, with a
              guarantee of cultural alignment.
            </p>
          </div>
          <div class="text-2xl max-w-md font-semibold text-primary-500 py-2">
            <div class="font-['Roboto'] font-semibold">Talent Growth</div>
            <p>
              Our program offers meaningful mentorships and development
              opportunities for growing talents.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<app-cta-section></app-cta-section>
