import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
})
export class LanguageListComponent {
  public languageList: any;
  constructor(
    public translate: TranslateService,
    private modalService: NzModalService
  ) {
    translate.addLangs(['en', 'nl', 'fr']);
    this.languageList = { en: 'English', nl: 'Dutch', fr: 'French' };
  }

  public handleNewLanguage(lang: string): void {
    this.translate.use(lang);
    localStorage.setItem('language', lang);
    this.modalService.closeAll();
  }
}
