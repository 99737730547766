import { Component, Input, OnInit } from '@angular/core';
import { ITeamMember } from '../../../interfaces/team-member';

@Component({
  selector: 'app-member-card',
  templateUrl: './member-card.component.html',
  styleUrls: ['./member-card.component.scss'],
})
export class MemberCardComponent implements OnInit {
  @Input() public member!: ITeamMember;

  constructor() {}

  ngOnInit(): void {}
}
