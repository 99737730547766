import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  IEducation,
  IExpertise,
  IRegion,
  IVacancy,
} from '../../../../interfaces/data';
import { Apollo, QueryRef } from 'apollo-angular';
import {
  AllEducations,
  AllFields,
  AllRegions,
  AllVacanciesFilter,
} from '../../../../graphql/queries';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { OnDestroy } from '@angular/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Draggable from 'gsap/Draggable';
import { VacanciesService } from '../../../../services/vacancies.service';
import { NzModalService } from 'ng-zorro-antd/modal';

interface IParams {
  educations?: Array<string>;
  regions?: Array<string>;
  fields?: Array<string>;
  search?: string;
}

interface CheckOptions {
  object: any;
  value: string;
  checked: boolean;
}

@UntilDestroy()
@Component({
  selector: 'app-jobs-page',
  templateUrl: './jobs-page.component.html',
  styleUrls: [
    './jobs-page.component.scss',
    '../../public-layout.component.scss',
  ],
})
export class JobsPageComponent implements OnInit, OnDestroy {
  public latestJobs: IVacancy[] = [];
  public regions: IRegion[] = [];
  public educations: IEducation[] = [];
  public expertises: IExpertise[] = [];
  public regionsQuery: Array<string> = [];
  public fieldsQuery: Array<string> = [];
  public educationsQuery: Array<string> = [];
  public employmentsQuery: Array<string> = [];
  public searchQuery = '';
  public httpParamsEducations: Array<string> = [];
  public httpParamsRegions: Array<string> = [];
  public httpParamsFields: Array<string> = [];
  public httpParams: IParams | undefined;
  public filterMenuOpen = true;

  public jobQuery!: QueryRef<any>;
  private querySubscription: Subscription = new Subscription();
  public timeline!: gsap.core.Timeline;
  @ViewChild('introText') introText!: ElementRef;
  @ViewChild('filterMenuContent') filterMenuContent!: TemplateRef<any>;

  indeterminate = false;
  public loading = false;
  public searchFilter = '';
  public gridMode = false;
  checkedExpertises: { [key: string]: boolean } = {};
  checkedRegions: { [key: string]: boolean } = {};
  checkedEducations: { [key: string]: boolean } = {};

  public constructor(
    private apollo: Apollo,
    public translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    public deviceService: DeviceDetectorService,
    private titleService: Title,
    private vacanciesService: VacanciesService,
    private modalService: NzModalService
  ) {}

  public ngOnInit(): void {
    this.loading = true;
    this.setTitle();
    this.setAnimations();

    this.route.queryParams.subscribe((params) => {
      this.regionsQuery = Array.isArray(params.regions)
        ? params.regions
        : params.regions
        ? [params.regions]
        : [];
      this.fieldsQuery = Array.isArray(params.fields)
        ? params.fields
        : params.fields
        ? [params.fields]
        : [];
      this.educationsQuery = Array.isArray(params.educations)
        ? params.educations
        : params.educations
        ? [params.educations]
        : [];
      this.employmentsQuery = Array.isArray(params.employments)
        ? params.employments
        : params.employments
        ? [params.employments]
        : [];
      this.searchQuery = params.search ? params.search : '';
      this.searchFilter = params.search ? params.search : '';

      this.regionsQuery.forEach((field) => (this.checkedRegions[field] = true));

      this.fieldsQuery.forEach(
        (field) => (this.checkedExpertises[field] = true)
      );

      this.educationsQuery.forEach(
        (field) => (this.checkedEducations[field] = true)
      );
    });

    this.getAllVacancies();
    this.getEducations();
    this.getFields();
    this.getRegions();
  }

  public async applyFilters() {
    this.loading = true;
    if (this.deviceService.isMobile()) {
      this.filterMenuOpen = false;
    }

    this.modalService.closeAll();

    await this.router
      .navigate(['/jobs'], {
        queryParams: {
          regions: this.httpParams?.regions,
          educations: this.httpParams?.educations,
          fields: this.httpParams?.fields,
          search: this.searchFilter,
        },
      })
      .then(() => {
        this.getAllVacancies();
        setTimeout(() => {
          this.scrollToTop();
        }, 350);
      });
  }

  public removeFilters() {
    Object.keys(this.checkedExpertises).forEach((key) => {
      this.checkedExpertises[key] = false;
    });
    Object.keys(this.checkedRegions).forEach((key) => {
      this.checkedRegions[key] = false;
    });
    Object.keys(this.checkedEducations).forEach((key) => {
      this.checkedEducations[key] = false;
    });

    this.httpParams = {
      regions: [],
      educations: [],
      fields: [],
      search: '',
    };

    this.searchFilter = '';
    this.modalService.closeAll();

    this.router
      .navigate(['/jobs'], {
        queryParams: {
          regions: null,
          educations: null,
          fields: null,
          search: null,
        },
      })
      .then(() => {
        this.getAllVacancies();
        setTimeout(() => {
          this.scrollToTop();
        }, 350);
      });
  }

  private getEducations(): void {
    this.vacanciesService
      .getEducations()
      .pipe(untilDestroyed(this))
      .subscribe((educations: IEducation[]) => {
        this.educations = educations;
      });
  }

  private getFields(): void {
    this.vacanciesService
      .getFields()
      .pipe(untilDestroyed(this))
      .subscribe((fields: IExpertise[]) => {
        this.expertises = fields;
      });
  }

  private getRegions(): void {
    this.vacanciesService
      .getRegions()
      .pipe(untilDestroyed(this))
      .subscribe((regions: IRegion[]) => {
        this.regions = regions;
      });
  }

  public getAllVacancies(): void {
    this.jobQuery = this.apollo.watchQuery<any>({
      query: AllVacanciesFilter,
      variables: {
        regions: this.regionsQuery,
        fields: this.fieldsQuery,
        educations: this.educationsQuery,
        employments: this.employmentsQuery,
        search: this.searchQuery,
      },
    });

    this.querySubscription = this.jobQuery.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(({ data, loading }) => {
        const sortArray = [...data?.vacancies];
        sortArray.sort((a: IVacancy, b: IVacancy) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );

        this.loading = loading;
        this.latestJobs = sortArray;
      });
  }

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }

  public setFiltersEducations(filters: any): void {
    this.httpParamsEducations = filters;
    this.httpParams = {
      ...this.httpParams,
      educations: this.httpParamsEducations,
    };
  }

  public setFiltersRegions(filters: string[]): void {
    this.httpParamsRegions = filters;
    this.httpParams = { ...this.httpParams, regions: this.httpParamsRegions };
  }

  public setFiltersFields(filters: string[]): void {
    this.httpParamsFields = filters;
    this.httpParams = { ...this.httpParams, fields: this.httpParamsFields };
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Bekijk alle banen';
    } else {
      title = 'View all careers';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

  setFilterMenu($event: boolean) {
    this.filterMenuOpen = $event;
  }

  openFilterModal() {
    this.modalService.create({
      nzContent: this.filterMenuContent,
      nzFooter: null,
      nzCentered: true,
      nzAutofocus: null,
      nzClosable: false,
      nzBodyStyle: {
        padding: '1.5rem',
      },
      // nzComponentParams: {
      //   member,
      // },
      nzWidth: '90%',
      nzStyle: {
        maxWidth: '1200px',
      },
    });
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  private setAnimations(): void {
    gsap.registerPlugin(ScrollTrigger, Draggable);

    gsap.from('.large-text-block', {
      opacity: 0,
      y: 50,
      duration: 0.8,
    });

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.introText?.nativeElement,
        start: 'bottom bottom',
        end: 'bottom bottom',
        toggleActions: 'play none none none',
      },
    });

    this.timeline.play();
  }
}
