import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FooterComponent } from './public-layout/footer/footer.component';
import { HeaderComponent } from './public-layout/header/header.component';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { RouterModule } from '@angular/router';
import { PublicLayoutComponent } from './public-layout/public-layout.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { HomePageComponent } from './public-layout/pages/home-page/home-page.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { JobsPageComponent } from './public-layout/pages/jobs-page/jobs-page.component';
import { JobCardComponent } from './components/job-card/job-card.component';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobDetailsPageComponent } from './public-layout/pages/job-details-page/job-details-page.component';
import { JobDetailCardComponent } from './components/job-detail-card/job-detail-card.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { AboutUsPageComponent } from './public-layout/pages/about-us-page/about-us-page.component';
import { ContactPageComponent } from './public-layout/pages/contact-page/contact-page.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NguCarouselModule } from '@ngu/carousel';
import { LanguageListComponent } from './components/language-list/language-list.component';
import { en_US, NZ_I18N } from 'ng-zorro-antd/i18n';
import { environment } from '../../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { DragScrollModule } from 'ngx-drag-scroll';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzSkeletonModule } from 'ng-zorro-antd/skeleton';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SanitizeHtmlPipe } from '../shared/pipes/sanitize-html.pipe';
import { ApplyErrorComponent } from './components/apply-components/apply-error/apply-error.component';
import { ApplySuccessComponent } from './components/apply-components/apply-success/apply-success.component';
import { PrivacyPolicyComponent } from './public-layout/pages/privacy-policy/privacy-policy.component';
import { FamilyPageComponent } from './public-layout/pages/family-page/family-page.component';
import { MemberCardComponent } from './components/member-card/member-card.component';
import { NewsPageComponent } from './public-layout/pages/news-page/news-page.component';
import { NewsDetailsPageComponent } from './public-layout/pages/news-details-page/news-details-page.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { EmployerPageComponent } from './public-layout/pages/employer-page/employer-page.component';
import { NzStepsModule } from 'ng-zorro-antd/steps';
import { CtaSectionComponent } from './components/cta-section/cta-section.component';
import { CareerSearcherComponent } from './components/career-searcher/career-searcher.component';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { NewsCardComponent } from './components/news-card/news-card.component';
import { GlobeModule } from './components/globe/globe.module';
import { ContactSuccessComponent } from './components/contact-form/contact-success/contact-success.component';
import { AbroadPageComponent } from './public-layout/pages/abroad-page/abroad-page.component';
import { SanitizeUrlPipe } from '../shared/pipes/sanitize-url.pipe';

@NgModule({
  declarations: [
    PublicLayoutComponent,
    FooterComponent,
    HeaderComponent,
    HomePageComponent,
    JobsPageComponent,
    JobCardComponent,
    JobDetailsPageComponent,
    JobDetailCardComponent,
    AboutUsPageComponent,
    ContactPageComponent,
    ContactFormComponent,
    LanguageListComponent,
    PageNotFoundComponent,
    SanitizeHtmlPipe,
    SanitizeUrlPipe,
    ApplyErrorComponent,
    ApplySuccessComponent,
    PrivacyPolicyComponent,
    FamilyPageComponent,
    MemberCardComponent,
    NewsPageComponent,
    NewsDetailsPageComponent,
    EmployerPageComponent,
    CtaSectionComponent,
    CareerSearcherComponent,
    NewsCardComponent,
    ContactSuccessComponent,
    AbroadPageComponent,
  ],
  imports: [
    CommonModule,
    NzLayoutModule,
    RouterModule,
    NzButtonModule,
    NzIconModule,
    NzDividerModule,
    NzCardModule,
    NzInputModule,
    NzCheckboxModule,
    FormsModule,
    NzModalModule,
    NzFormModule,
    ReactiveFormsModule,
    NzCollapseModule,
    NguCarouselModule,
    HttpClientModule,
    TranslateModule,
    NzAvatarModule,
    DragScrollModule,
    NzEmptyModule,
    NzSkeletonModule,
    NzUploadModule,
    NzSelectModule,
    NgOptimizedImage,
    NzMenuModule,
    NzStepsModule,
    NzDrawerModule,
    GlobeModule,
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: 'BASE_API_URL', useValue: environment.API_URL },
    { provide: 'BASE_DOMAIN', useValue: environment.DOMAIN },
  ],
  exports: [PublicLayoutComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}

export function throwIfAlreadyLoaded(
  parentModule: any,
  moduleName: string
): any {
  if (parentModule) {
    throw new Error(
      `${moduleName} has already been loaded. Import ${moduleName} modules in the AppModule only.`
    );
  }
}
