import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { IBlog } from '../../../../interfaces/data';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Draggable from 'gsap/Draggable';
import { AllBlogs, AllVacanciesFilter } from '../../../../graphql/queries';
import { Apollo } from 'apollo-angular';

@Component({
  selector: 'app-news-page',
  templateUrl: './news-page.component.html',
  styleUrls: [
    './news-page.component.scss',
    '../../public-layout.component.scss',
  ],
})
export class NewsPageComponent implements OnInit {
  public newsItems: IBlog[] = [];
  public timeline!: gsap.core.Timeline;
  @ViewChild('introText') introText!: ElementRef;

  constructor(
    private titleService: Title,
    public translate: TranslateService,
    private apollo: Apollo
  ) {}

  ngOnInit(): void {
    this.setTitle();
    this.setAnimations();
    this.getBlogs();
  }

  public setTitle(): void {
    let title = '';
    if (this.translate.currentLang === 'nl') {
      title = 'Motus Nieuws';
    } else {
      title = 'Motus News';
    }
    this.titleService.setTitle(title + ' - Motus People');
  }

  private getBlogs(): void {
    this.apollo
      .watchQuery<any>({
        query: AllBlogs,
      })
      .valueChanges.subscribe((result) => {
        const sortArray = [...result?.data?.blogs];
        sortArray.sort((a: IBlog, b: IBlog) =>
          String(b.updatedAt).localeCompare(String(a.updatedAt))
        );
        this.newsItems = sortArray;
      });
  }

  private setAnimations(): void {
    gsap.registerPlugin(ScrollTrigger, Draggable);

    gsap.from('.large-text-block', {
      opacity: 0,
      y: 50,
      duration: 0.8,
    });

    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.introText?.nativeElement,
        start: 'bottom bottom',
        end: 'bottom bottom',
        toggleActions: 'play none none none',
      },
    });

    this.timeline.from('.news-grid', {
      opacity: 0,
      y: 50,
      duration: 0.5,
    });

    this.timeline.play();
  }
}
