<section class="jobs-intro-section bg-gray-100 relative">
  <div class="jobs-intro-section-inner">
    <div class="background-motus-logo">
      <img
        src="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
        alt="background-logo"
        class="w-full h-full object-cover opacity-5"
      />
    </div>

    <div class="large-text-block relative z-10 lg:my-8">
      <div #introText>
        <h1 class="intro-title mb-4">Find Your New Opportunity</h1>
      </div>
      <app-career-searcher
        [hasSecondaryColor]="true"
        (notifyParent)="applyFilters()"
      ></app-career-searcher>
      <p class="mt-4">
        <ng-container *ngIf="latestJobs.length < 1"
          >No results, try a different search</ng-container
        >

        <ng-container *ngIf="latestJobs.length === 1"
          >{{ latestJobs.length }} result found</ng-container
        >
        <ng-container *ngIf="latestJobs.length > 1"
          >{{ latestJobs.length }} results found</ng-container
        >
      </p>
    </div>
  </div>
</section>

<section class="jobs-view pt-8 md:pt-0 relative">
  <div
    class="hidden lg:flex justify-end mx-auto max-w-screen-2xl px-6 2xl:px-8 py-4"
  >
    <nz-button-group>
      <button
        (click)="gridMode = false"
        nz-button
        nzSize="large"
        [nzType]="gridMode ? 'default' : 'primary'"
      >
        <span nz-icon nzType="unordered-list"></span>
      </button>
      <button
        (click)="gridMode = true"
        nz-button
        nzSize="large"
        [nzType]="gridMode ? 'primary' : 'default'"
      >
        <span nz-icon nzType="appstore"></span>
      </button>
    </nz-button-group>
  </div>

  <div class="lg:flex mx-auto max-w-screen-2xl px-6 2xl:px-8">
    <div class="hidden md:block pt-0 md:py-8 pb-5">
      <nz-collapse
        class="sticky mr-8 top-40 rounded-2xl text-xl shadow-lg w-full lg:w-72"
      >
        <nz-collapse-panel
          (nzActiveChange)="setFilterMenu($event)"
          [nzHeader]="filters"
          [nzActive]="filterMenuOpen"
        >
          <ng-template #filters>
            <div class="select-none text-secondary-500 font-bold">
              {{ "JOBS_PAGE.FILTER.FILTERS" | translate }}
            </div>
          </ng-template>
          <ng-container *ngTemplateOutlet="filterMenuContent"></ng-container>
        </nz-collapse-panel>
      </nz-collapse>
    </div>

    <div
      [ngClass]="
        gridMode
          ? 'grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3'
          : 'flex flex-col'
      "
      class="gap-6 pt-8 pb-8 jobs-list w-full h-fit"
    >
      <ng-container *ngIf="!loading; else loader">
        <ng-container *ngFor="let job of latestJobs">
          <app-job-card [gridMode]="gridMode" [job]="job"></app-job-card>
        </ng-container>
      </ng-container>
      <ng-template #loader>
        <ng-container *ngFor="let index of [0, 1, 2, 3, 4, 5]">
          <nz-skeleton
            class="py-6"
            *ngIf="loading"
            [nzActive]="true"
            [nzTitle]="false"
            [nzLoading]="true"
          ></nz-skeleton>
        </ng-container>
      </ng-template>
      <div
        *ngIf="latestJobs.length === 0 && !loading"
        class="flex justify-center items-center w-full"
      >
        <nz-card class="w-full rounded-2xl shadow-lg">
          <nz-empty
            class="p-10"
            [nzNotFoundContent]="'COMPONENT.EMPTY.JOBS' | translate"
          ></nz-empty>
        </nz-card>
      </div>
    </div>
  </div>

  <div
    class="bg-logo-overlay w-full md:w-4/5 xl:w-3/5 absolute top-0 left-0 h-full"
  ></div>

  <div class="fixed flex md:hidden flex-col gap-2 bottom-4 right-4 z-20">
    <button
      (click)="scrollToTop()"
      nz-button
      nzSize="large"
      nzShape="circle"
      class="h-12 w-12"
    >
      <span nz-icon nzType="up" nzTheme="outline"></span>
    </button>
    <button
      nz-button
      nzType="primary"
      nzSize="large"
      nzShape="circle"
      class="h-12 w-12"
      (click)="openFilterModal()"
    >
      <span nz-icon nzType="filter" nzTheme="outline"></span>
    </button>
  </div>
</section>

<ng-template #filterMenuContent>
  <div class="pb-2">
    <nz-input-group [nzSuffix]="suffixIconSearch">
      <input
        class=""
        [(ngModel)]="searchFilter"
        type="text"
        nz-input
        (keyup.enter)="applyFilters()"
      />
    </nz-input-group>
    <ng-template #suffixIconSearch>
      <i
        class="cursor-pointer"
        (click)="applyFilters()"
        nz-icon
        nzType="search"
      ></i>
    </ng-template>
  </div>
  <div class="flex gap-2 pb-4">
    <button
      class="w-full"
      nz-button
      nzType="primary"
      nzShape="round"
      (click)="applyFilters()"
    >
      Apply filters
    </button>
    <button nz-button (click)="removeFilters()">
      <i nz-icon nzType="filter" class="text-red-600" nzTheme="outline"></i>
      Clear filters
    </button>
  </div>

  <nz-checkbox-wrapper class="w-full" (nzOnChange)="setFiltersFields($event)">
    <h4 class="text-lg font-semibold font-['Roboto'] pt-2">
      <span class="text-secondary-500 pr-0.5" nz-icon nzType="setting"></span>
      {{ "JOBS_PAGE.FILTER.EXPERTISES" | translate }}
    </h4>
    <ul class="pt-1">
      <li *ngFor="let expertise of expertises">
        <label
          class="text-base"
          [(ngModel)]="checkedExpertises[expertise.slug]"
          nz-checkbox
          nzValue="{{ expertise?.slug }}"
        >
          {{
            translate.currentLang === "nl"
              ? expertise?.name?.nl
              : expertise?.name?.en
          }}
        </label>
      </li>
    </ul>
  </nz-checkbox-wrapper>

  <nz-checkbox-wrapper class="w-full" (nzOnChange)="setFiltersRegions($event)">
    <h4 class="text-lg font-semibold font-['Roboto'] pt-2">
      <span
        class="text-secondary-500 pr-0.5"
        nz-icon
        nzType="environment"
      ></span>
      {{ "JOBS_PAGE.FILTER.REGION" | translate }}
    </h4>
    <ul class="pt-1">
      <li *ngFor="let region of regions">
        <label
          class="text-base"
          [(ngModel)]="checkedRegions[region.slug]"
          nz-checkbox
          nzValue="{{ region?.slug }}"
        >
          {{
            translate.currentLang === "nl" ? region?.name?.nl : region?.name?.en
          }}</label
        >
      </li>
    </ul>
  </nz-checkbox-wrapper>

  <nz-checkbox-wrapper
    class="w-full"
    (nzOnChange)="setFiltersEducations($event)"
  >
    <h4 class="text-lg font-semibold font-['Roboto'] pt-2">
      <span class="text-secondary-500 pr-1" nz-icon nzType="bulb"></span
      >{{ "JOBS_PAGE.FILTER.EDUCATION" | translate }}
    </h4>
    <ul class="pt-1">
      <li *ngFor="let education of educations">
        <label
          class="text-base"
          [(ngModel)]="checkedEducations[education.slug]"
          nz-checkbox
          nzValue="{{ education?.slug }}"
        >
          {{
            translate.currentLang === "nl"
              ? education?.name?.nl
              : education?.name?.en
          }}</label
        >
      </li>
    </ul>
  </nz-checkbox-wrapper>
</ng-template>
