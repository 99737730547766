import {
  Component,
  AfterViewInit,
  ElementRef,
  ViewChild,
  HostListener,
  Input,
} from '@angular/core';
import Globe, { GlobeInstance } from 'globe.gl';
import * as d3 from 'd3';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.scss'],
})
export class GlobeComponent implements AfterViewInit {
  @Input() hasTransparentBackground = false;
  @Input() drawLines = false;
  @ViewChild('globeContainer', { static: true }) globeContainer!: ElementRef;
  globe!: GlobeInstance;

  constructor(private deviceService: DeviceDetectorService) {}

  ngAfterViewInit(): void {
    this.initGlobe();
    this.updateGlobeSize();
    this.loadCountriesData();
  }

  private initGlobe(): void {
    this.globe = Globe();
    this.globe(this.globeContainer.nativeElement);
    // Configure and customize the globe as needed

    const N = 6;
    const arcsData = [...Array(N).keys()].map(() => ({
      startLat: (Math.random() - 0.5) * 180,
      startLng: (Math.random() - 0.5) * 360,
      endLat: (Math.random() - 0.5) * 180,
      endLng: (Math.random() - 0.5) * 360,
      color: [
        ['#F6921E', '#F6921E', '#F6921E', 'rgb(185 28 28)'][
          Math.round(Math.random() * 3)
        ],
        ['#F6921E', 'rgb(185 28 28)', '#F6921E', '#F6921E'][
          Math.round(Math.random() * 3)
        ],
      ],
    }));

    this.globe
      .globeImageUrl('//unpkg.com/three-globe/example/img/earth-night.jpg')
      .bumpImageUrl('//unpkg.com/three-globe/example/img/earth-topology.png')
      .arcsData(this.drawLines ? arcsData : [])
      .arcColor('color')
      .arcDashLength(() => 0.8)
      .arcDashGap(() => 1)
      .arcDashAnimateTime(() => Math.random() * 5000 + 500);

    if (this.hasTransparentBackground) {
      this.globe.backgroundColor('rgb(0,0,0,0)');
    } else {
      this.globe.backgroundImageUrl(
        '//unpkg.com/three-globe/example/img/night-sky.png'
      );
    }

    this.globe.controls().autoRotate = true;
    this.globe.controls().autoRotateSpeed = 0.6;
    this.globe.controls().enableZoom = false;
    this.globe.pointOfView({
      lat: 30,
      lng: 0,
      altitude: this.deviceService.isMobile() ? 5 : 3,
    });
  }

  private updateGlobeSize(): void {
    const container = this.globeContainer.nativeElement;
    // Hack to fix side scroll bar (the minus 1 part)
    this.globe.width(container.clientWidth - 1).height(container.clientHeight);
  }

  private loadCountriesData(): void {
    // Load GeoJSON data containing country boundaries
    d3.json('/assets/custom.geo.json').then((data: any) => {
      const countries = [
        'RU',
        'CA',
        'AU',
        'IN',
        'US',
        'BR',
        'ZA',
        'NL',
        'ES',
        'PT',
        'IT',
        'DE',
        'BE',
        'TR',
        'UA', // Add more countries here
        'MA',
        'IE',
        'GR',
        'AL',
        'HR',
        'CZ',
        'PL',
        'RO',
        'BG',
        'NG',
        'VE',
        'SI',
        'HU',
        'LT',
        'KE', // Additional countries
      ];

      // Save default settings

      // Initialize an array to store all country data
      const allCountryData: any[] = [];

      // Collect data for each country
      countries.forEach((countryCode) => {
        const countryData = data.features.filter((feature: any) => {
          return feature.properties.iso_a2 === countryCode;
        });

        allCountryData.push(...countryData);
      });

      // Initialize polygons data with all countries
      this.globe.polygonsData(allCountryData);

      // Change the color of all countries to orange
      this.globe
        .polygonCapColor(() => 'rgba(246, 146, 30, 0.8)')
        .polygonSideColor(() => 'rgba(250, 249, 246, 0.8)');
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: Event): void {
    this.updateGlobeSize();
  }
}
