import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Location } from '@angular/common';
import { ITitle, IVacancy } from '../../../../interfaces/data';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subscription } from 'rxjs';
import { ContactFormComponent } from '../../../components/contact-form/contact-form.component';
import { DragScrollComponent } from 'ngx-drag-scroll';
import { Apollo } from 'apollo-angular';
import { TranslateService } from '@ngx-translate/core';
import { VacancyById } from '../../../../graphql/queries';
import { switchMap } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { allTeamMembers } from 'src/app/shared/variables';
import { ITeamMember } from '../../../../interfaces/team-member';

untilDestroyed(this);

@UntilDestroy()
@Component({
  selector: 'app-job-details-page',
  templateUrl: './job-details-page.component.html',
  styleUrls: ['./job-details-page.component.scss'],
})
export class JobDetailsPageComponent implements OnInit, OnDestroy {
  // @ts-ignore
  @ViewChild('nav', { read: DragScrollComponent }) ds: DragScrollComponent;
  public jobId = '';
  public jobTitle: ITitle | undefined;

  public job!: IVacancy;
  public job$: Observable<any> = new Observable<IVacancy>();
  private querySubscription: Subscription = new Subscription();
  protected readonly allTeamMembers = allTeamMembers;
  protected readonly currentUrl = window.location.href;

  loading = false;
  isApplying = false;
  jobOwner: ITeamMember = this.allTeamMembers[0];
  applyingForMotus = false;

  constructor(
    private route: ActivatedRoute,
    private apollo: Apollo,
    public translate: TranslateService,
    private titleService: Title
  ) {}

  ngOnDestroy(): void {
    this.querySubscription.unsubscribe();
  }

  ngOnInit(): void {
    this.loading = true;

    this.route.params.pipe(untilDestroyed(this)).subscribe(({ jobId = '' }) => {
      if (jobId === 'motus-apply') {
        this.applyingForMotus = true;
        this.loading = false;
        this.isApplying = true;
      }
      this.jobId = jobId;
    });

    if (!this.applyingForMotus) {
      this.job$ = this.route.paramMap.pipe(
        switchMap((params) => {
          this.jobId = String(params.get('jobId'));
          return this.apollo.watchQuery({
            query: VacancyById,
            variables: {
              vacancyId: this.jobId,
            },
          }).valueChanges;
        })
      );

      this.job$.pipe(untilDestroyed(this)).subscribe(({ data }: any) => {
        this.job = data.vacancy;
        this.compareJobOwnerToMember();

        let title = '';
        if (this.translate.currentLang === 'nl') {
          title = data.vacancy.title.nl;
        } else {
          title = data.vacancy.title.en;
        }
        this.titleService.setTitle(title + ' - Motus People');

        this.loading = data.loading;
      });
    }
  }

  compareJobOwnerToMember(): void {
    const jobOwner = this.job.owner;
    const familyMembers = this.allTeamMembers;

    this.jobOwner =
      familyMembers.find((teamMember) => teamMember.email === jobOwner.email) ||
      this.allTeamMembers[0];
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
