<form
  id="new-row"
  class="w-full"
  nz-form
  nzLayout="vertical"
  [formGroup]="contactForm"
  (ngSubmit)="onSubmit()"
  [nzAutoTips]="autoTips"
>
  <nz-form-item>
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.FIRST_NAME" | translate }}
      <span class="text-secondary-500">&nbsp;*</span></nz-form-label
    >
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          id="firstName"
          name="firstName"
          formControlName="firstName"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.LAST_NAME" | translate }}
      <span class="text-secondary-500">&nbsp;*</span></nz-form-label
    >
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          id="lastName"
          name="lastName"
          formControlName="lastName"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.E-MAIL" | translate }}
      <span class="text-secondary-500">&nbsp;*</span></nz-form-label
    >
    <nz-form-control>
      <nz-input-group>
        <input
          type="email"
          nz-input
          id="email"
          name="email"
          formControlName="email"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item>
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.PHONE" | translate }}
      <span class="text-secondary-500">&nbsp;*</span></nz-form-label
    >
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          id="phoneNumber"
          name="phoneNumber"
          formControlName="phoneNumber"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="isApply">
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.NATIONALITY" | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          id="nationality"
          name="nationality"
          formControlName="nationality"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="!isApply">
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.SUBJECT" | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-input-group>
        <input
          type="text"
          nz-input
          id="subject"
          name="subject"
          formControlName="subject"
        />
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-form-item *ngIf="!isApply">
    <nz-form-label>
      {{ "COMPONENT.CONTACT_FORM.REMARKS" | translate }}
    </nz-form-label>
    <nz-form-control>
      <nz-input-group>
        <textarea
          rows="5"
          type="text"
          nz-input
          id="remark"
          name="remark"
          formControlName="remark"
        ></textarea>
      </nz-input-group>
    </nz-form-control>
  </nz-form-item>

  <nz-card class="mt-8" *ngIf="isApply">
    <nz-form-item>
      <nz-form-label> LinkedIn URL </nz-form-label>
      <nz-form-control>
        <nz-input-group>
          <input
            type="text"
            nz-input
            id="linkedin"
            name="linkedin"
            formControlName="linkedin"
          />
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <nz-divider
      nzText="{{ 'COMPONENT.CONTACT_FORM.APPLY_ALTERNATIVE' | translate }}"
    ></nz-divider>

    <nz-form-item>
      <nz-form-control>
        <nz-input-group>
          <nz-upload
            [nzBeforeUpload]="beforeUpload"
            [nzHeaders]="{ authorization: 'authorization-text' }"
            [nzAccept]="['.pdf']"
            [nzLimit]="1"
          >
            <a nz-button (click)="emptyUploadList()">
              <i nz-icon nzType="upload"></i>
              Upload
            </a>
          </nz-upload>
        </nz-input-group>
      </nz-form-control>
    </nz-form-item>

    <div>{{ uploads[0]?.name }}</div>
  </nz-card>

  <div class="w-full flex pt-6">
    <button
      class="text-white font-bold border-2 border-solid border-primary-500 bg-primary-500"
      nz-button
      type="submit"
      [nzSize]="'large'"
      nzShape="round"
    >
      <ng-container *ngIf="isApply; else contact">
        <span class="px-4">Submit your application</span>
      </ng-container>
      <ng-template #contact>
        <span class="px-4">Send message</span>
      </ng-template>
    </button>
  </div>
</form>
