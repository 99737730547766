<div class="relative min-h-screen flex flex-col justify-between">
  <div>
    <app-header #header></app-header>
    <div id="motus-content" class="content-grid">
      <main>
        <router-outlet></router-outlet>
      </main>
    </div>
  </div>
  <app-footer></app-footer>
</div>
