<div class="bg-gray-100">
  <section
    class="relative cover bg-fixed bg-gradient-to-tr from-teal-500 to-75% to-primary-500 overflow-hidden py-48 flex items-center"
  >
    <img
      ngSrc="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Wit.svg"
      alt="Motus People logo"
      class="w-full h-full absolute top-0 left-0 object-cover opacity-10"
      height="16"
      width="16"
    />
    <div class="container z-10">
      <h1
        class="text-white text-4xl sm:text-5xl md:text-6xl lg:text-7xl xl:text-8xl font-extrabold"
      >
        Work with Motus People
      </h1>
      <p
        class="max-w-2xl text-lg md:text-2xl font-bold leading-snug mt-4 pb-12 text-gray-200"
      >
        At Motus People, we connect you with top-tier candidates who meet your
        needs and align with your company culture. Whether it's temporary
        positions, project-based work, or permanent roles, we're here to help
        every step of the way.
      </p>
      <button
        nz-button
        [nzSize]="'large'"
        nzShape="round"
        [routerLink]="['../contact']"
      >
        Get in touch with Motus
      </button>
    </div>
  </section>
</div>

<section class="relative py-12 md:py-32">
  <div class="container flex flex-col">
    <h3 class="text-3xl md:text-4xl font-semibold">Work with Motus People</h3>
    <p class="max-w-3xl mb-8 mt-4">
      At Motus People, we connect you with top-tier candidates who meet your
      needs and align with your company culture. Whether it's temporary
      positions, project-based work, or permanent roles, we're here to help
      every step of the way.
    </p>

    <div class="grid grid-cols-1 md:grid-cols-2 gap-8 md:gap-12 text-lg mt-4">
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">Personalized Service</div>
        <p>
          We tailor our recruitment strategies to your unique needs, providing
          comprehensive candidate searches, interview coordination, and hiring
          facilitation, exceeding your expectations.
        </p>
      </div>
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">Extensive Network</div>
        <p>
          With access to a diverse pool of top talent, we connect you with
          qualified candidates ready to contribute to your team in various
          industries.
        </p>
      </div>
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">Quality Assurance</div>
        <p>
          Our rigorous screening process ensures that every candidate meets our
          high standards. We verify credentials, conduct thorough interviews,
          and assess skills and experiences to ensure a perfect fit for your
          organization.
        </p>
      </div>
      <div class="text-2xl font-semibold text-primary-500 py-2">
        <div class="font-['Roboto'] font-semibold">
          Efficiency and Timeliness
        </div>
        <p>
          We understand the importance of quick, efficient hires to keep your
          business running smoothly. Our team streamlines the process to deliver
          timely results, saving you time and resources.
        </p>
      </div>
    </div>
  </div>
</section>

<section class="relative bg-gradient-to-b from-primary-900 to-primary-400">
  <div
    class="hidden md:block sticky top-0 w-[44rem] h-[44rem] left-0 text-xl text-white"
  >
    <app-globe [hasTransparentBackground]="true"></app-globe>
  </div>
  <div class="container md:-mt-96 py-12">
    <div class="flex w-full justify-center">
      <h2
        class="text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl text-gray-50 font-extrabold z-50"
      >
        The Motus People Way
      </h2>
    </div>

    <div
      class="pt-10 md:pt-20 space-y-8 relative before:absolute before:inset-0 before:ml-5 before:-translate-x-px md:before:mx-auto md:before:translate-x-0 before:h-full before:w-1 before:bg-gradient-to-b before:from-transparent before:via-secondary-500 before:to-transparent"
    >
      <!-- Item #1 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          1
        </div>

        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">
            Introduction meeting
          </div>
          <p>
            Our digital introduction meeting aligns objectives and clarifies
            collaboration processes for a smooth partnership.
          </p>
        </div>
      </div>

      <!-- Item #2 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          2
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">Job matching</div>
          <p>
            Our job matching service aligns your skills, experiences, and
            preferences to find your dream job.
          </p>
        </div>
      </div>

      <!-- Item #3 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          3
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">
            Preparation for client interview
          </div>
          <p>
            Our interview preparation ensures you're ready to impress,
            confidently showcasing your skills.
          </p>
        </div>
      </div>

      <!-- Item #4 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          4
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">Job interview</div>
          <p>
            The floor is yours, go get 'em! We're here to support you all the
            way.
          </p>
        </div>
      </div>

      <!-- Item #4 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          5
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">Signing contract</div>
          <p>
            Time to celebrate! This marks the start of our journey together.
          </p>
        </div>
      </div>

      <!-- Item #4 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group is-active"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-secondary-300 group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          6
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-white p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div class="font-black font-['Roboto'] text-xl">
            Relocation support
          </div>
          <p>
            Relax, we take care of it. We're here to help you every step of the
            way.
          </p>
        </div>
      </div>

      <!-- Item #5 -->
      <div
        class="relative flex items-center justify-between md:justify-normal md:odd:flex-row-reverse group"
      >
        <!-- Icon -->
        <div
          class="flex items-center justify-center w-10 h-10 rounded-full bg-white group-[.is-active]:bg-white text-secondary-500 group-[.is-active]:text-secondary-500 font-black font-['Roboto'] text-xl shadow shrink-0 md:order-1 md:group-odd:-translate-x-1/2 md:group-even:translate-x-1/2"
        >
          <img
            src="assets/SVG/Logo_RGB_Motus_People_Beeldmerk_Oranje.svg"
            alt="motus-logo"
          />
        </div>
        <!-- Card -->
        <div
          class="w-[calc(100%-4rem)] md:w-[calc(50%-2.5rem)] bg-secondary-500 p-4 md:p-6 rounded-2xl shadow-lg"
        >
          <div
            class="font-extrabold font-['Roboto'] text-xl md:text-3xl text-white"
          >
            Start of a new adventure
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="relative bg-gradient-to-b from-primary-400 to-primary-500">
  <app-cta-section></app-cta-section>
</section>
