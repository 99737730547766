import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AllEducations, AllFields, AllRegions } from '../graphql/queries';
import { IEducation, IExpertise, IRegion } from '../interfaces/data';
import { TranslateService } from '@ngx-translate/core';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class VacanciesService {
  constructor(private apollo: Apollo, private translate: TranslateService) {}

  public getEducations(): Observable<IEducation[]> {
    return this.apollo
      .watchQuery({
        query: AllEducations,
      })
      .valueChanges.pipe(
        map((result: any) => {
          const sortArray = [...result?.data?.educations];
          if (this.translate.currentLang === 'nl') {
            sortArray.sort((a: IEducation, b: IEducation) =>
              a.name.nl.localeCompare(b.name.nl)
            );
          } else {
            sortArray.sort((a: IEducation, b: IEducation) =>
              a.name.en.localeCompare(b.name.en)
            );
          }
          return sortArray;
        })
      );
  }

  public getFields(): Observable<IExpertise[]> {
    return this.apollo
      .watchQuery({
        query: AllFields,
      })
      .valueChanges.pipe(
        map((result: any) => {
          const sortArray = [...result?.data?.fields];
          if (this.translate.currentLang === 'nl') {
            sortArray.sort((a: IExpertise, b: IExpertise) =>
              a.name.nl.localeCompare(b.name.nl)
            );
          } else {
            sortArray.sort((a: IExpertise, b: IExpertise) =>
              a.name.en.localeCompare(b.name.en)
            );
          }
          return sortArray;
        })
      );
  }

  public getRegions(): Observable<IRegion[]> {
    return this.apollo
      .watchQuery({
        query: AllRegions,
      })
      .valueChanges.pipe(
        map((result: any) => {
          const sortArray = [...result?.data?.regions];
          if (this.translate.currentLang === 'nl') {
            sortArray.sort((a: IRegion, b: IRegion) =>
              a.name.nl.localeCompare(b.name.nl)
            );
          } else {
            sortArray.sort((a: IRegion, b: IRegion) =>
              a.name.en.localeCompare(b.name.en)
            );
          }
          return sortArray;
        })
      );
  }
}
